import { Component, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { LogOutAction } from 'src/app/core/auth/auth.actions';
import { fadeOut } from 'src/app/shared/animations/fade';
import { hasValue } from 'src/app/shared/empty.util';

/**
 * Component representing the avatar navbar
 */
@Component({
  selector: 'ds-avatar',
  styleUrls: ['./avatar-content.component.scss'],
  templateUrl: './avatar-content.component.html',
  animations: [fadeOut],
})
export class AvatarComponent implements OnChanges {
  @Input() user;
  @Input() showName;
  dataUserAction: any = {};
  dropdownOpen = false;
  userImage: string | null = null;

  constructor(private store: Store<AppState>) {}

  ngOnChanges(): void {
    if (hasValue(this.user)) {
      const { firstName, phone, email, lastName } = this.user;
      this.dataUserAction = {
        userName: firstName ? firstName : null,
        phone: phone ? phone : null,
        lastName: lastName ? lastName : null,
        email: email ? email : null,
      };
    }
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  // Get initial letter name user
  getInitials(name: string) {
    return name ? name.charAt(0).toUpperCase() : '';
  }

  public logOut() {
    this.store.dispatch(new LogOutAction());
  }
}
