<section class="elementor" [ngClass]="{'spacing': !isLoading}">
    <div *ngIf="isLoading" class="loader-container">
        <ds-small-loader [isLoading]="true"></ds-small-loader>
    </div>
    <div *ngIf="!isLoading">
        <div class="elementor-background-overlay" style="background-image: url('{{dataContact?.image}}')"></div>
        <div class="elementor-container elementor-column-gap-default">
            <div
                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a065dfd">
                <div class="elementor-widget-wrap elementor-element-populated">
                    <div class="elementor-element elementor-element-94dddf6 elementor-widget elementor-widget-heading">
                        <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">
                                <strong>{{dataContact?.title}}</strong>
                            </h2>
                        </div>
                    </div>
                    <div class="elementor-element elementor-element-1115ff3 elementor-widget elementor-widget-heading">
                        <div class="elementor-widget-container elementor-subwidget-container ">
                            <h3 class="elementor-heading-subtitle elementor-size-default">{{dataContact?.description}}
                            </h3>
                        </div>
                    </div>
                    <section
                        class="elementor-inner-section elementor-section elementor-element elementor-element-262799c elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                        <div *ngIf="dataContact?.items?.length "
                            class="elementor-container elementor-column-gap-default">
                            <div *ngFor="let data of dataContact.items" class="elementor-column-container">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element-container elementor-element-68bba86 bdt-icon-type-image elementor-position-top bdt-icon-effect-none elementor-widget elementor-widget-bdt-advanced-icon-box"
                                        data-widget_type="bdt-advanced-icon-box.default">
                                        <div class="elementor-widget-container">
                                            <div class="bdt-ep-advanced-icon-box">
                                                <div class="bdt-ep-advanced-icon-box-icon">
                                                    <span class="bdt-ep-advanced-icon-box-icon-wrap">
                                                        <img *ngIf="data.srcImg" alt="Correo:" class="lazy loaded"
                                                            [src]="data.srcImg">
                                                    </span>
                                                </div>
                                                <div class="bdt-ep-advanced-icon-box-content">
                                                    <h3 class="bdt-ep-advanced-icon-box-title">
                                                        <span>{{data?.title}}</span>
                                                    </h3>
                                                    <div class="bdt-ep-advanced-icon-box-description">
                                                        <p>{{data?.description}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</section>