import { TypeDataContact } from './about-us.model';
import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'ds-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnChanges {
  @Input() isLoading;
  dataContact: TypeDataContact = {};
  @Input() data: TypeDataContact = {};

  ngOnChanges(): void {
    this.dataContact = this.data;
  }
}
