/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { ActivatedRoute, Router } from '@angular/router';
import { PdfService } from 'src/app/core/data/pdf.service';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ItemNavbarComponent } from 'src/app/item-navbar/item-navbar.component';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { updateMetaTags } from './handleMeta';
import { Meta, Title } from '@angular/platform-browser';
import { catchError, filter, map, of } from 'rxjs';

@Component({
  selector: 'ds-view-item',
  styleUrls: ['./detail-view.component.scss'],
  templateUrl: './detail-view.component.html',
})
export class DetailViewItemComponent
  extends ItemNavbarComponent
  implements OnInit
{
  @Input() object;
  id: string;
  src: string;
  isLoadingImage = true;
  extractedData = { primaryData: [], secondData: [] };
  showButtons = false;
  documentTitle = '';
  modalIsOpen = false;
  documentUrl = '';

  // Metadata for tags
  metadataLabels = {
    primaryData: {
      'dc.title': 'Título',
      'dc.description': 'Descripción',
      'dc.contributor.author': 'Autores',
    },
    secondData: {
      'dc.date.issued': 'Fecha',
      'dc.language.iso': 'Idioma',
      'dc.publisher': 'Editorial',
    },
  };

  constructor(
    public router: Router,
    public pdfService: PdfService,
    public der: ChangeDetectorRef,
    private meta: Meta,
    public title: Title,
    public activeRoute: ActivatedRoute,
    public notify: NotificationsService
  ) {
    super(router, pdfService, der, activeRoute, notify);
  }

  ngOnInit() {
    this.loadData();
    this.checkIfPdfExists();
  }

  loadData(): void {
    if (this.object && this.object.metadata) {
      const orderedKeys = {
        primaryData: ['dc.title', 'dc.contributor.author', 'dc.description'],
        secondData: ['dc.date.issued', 'dc.publisher', 'dc.language.iso'],
      };

      // Process primary and secondary metadata
      this.extractMetadata(orderedKeys.primaryData, 'primaryData');
      this.extractMetadata(orderedKeys.secondData, 'secondData');
      this.resetState();
      this.handleMetaTags();
      this.der.detectChanges();
    }
  }

  resetState(): void {
    this.isLoadingImage = true;
    this.showButtons = false;
    this.pdfService.clearPdfLink();
  }

  // Method for extracting metadata based on keys and type (primary or second)
  private extractMetadata(
    keys: string[],
    dataType: 'primaryData' | 'secondData'
  ): void {
    for (const key of keys) {
      const value = this.object.metadata[key];
      if (value) {
        if (Array.isArray(value)) {
          for (const entry of value) {
            this.extractedData[dataType].push({
              label: this.metadataLabels[dataType][key],
              value: entry?.value || 'No hay datos disponibles',
            });
          }
        }
      } else {
        this.extractedData[dataType].push({
          label: this.metadataLabels[dataType][key],
          value: 'No hay datos disponibles',
        });
      }
    }
    this.documentTitle = this.object?._name;
    this.documentUrl = window.location?.href;
  }

  onLoadingStatusChanged(loading: boolean) {
    this.isLoadingImage = loading;
  }

  checkIfPdfExists(): void {
    this.pdfService.getLink().subscribe((link) => {
      if (link) {
        this.showButtons = true;
      } else {
        this.showButtons = false;
      }
      this.isLoadingImage = false;
    });
  }
  handleMetaTags() {
    const data = {
      title: '',
      url: '',
      description: '',
      image: '',
    };

    data.title = this.object?.metadata['dc.title']
      ? this.object?.metadata['dc.title']?.[0]?.value
      : '';
    data.url = this.parseUrl(`/items/${this.object?.id}`);
    data.description =
      (this.object?.metadata['dc.description']
        ? this.object?.metadata['dc.description'][0]?.value + '. '
        : '') +
      (this.object?.metadata['dc.publisher']
        ? this.object?.metadata['dc.publisher'][0]?.value + '. '
        : '') +
      (this.object?.metadata['dc.contributor.author']
        ? 'Autores: ' +
          this.object?.metadata?.['dc.contributor.author'][0]?.value +
          '. '
        : '') +
      (this.object?.metadata['dc.date.issued']
        ? this.object?.metadata['dc.date.issued'][0]?.value
        : '');

    this.object?.thumbnail
      .pipe(
        filter((img) => img !== null && img !== undefined),

        map((img) => {
          return img;
        }),

        catchError((error) => {
          console.error('Error al cargar la miniatura:', error);
          return of(null);
        })
      )
      .subscribe((img) => {
        if (img) {
          data.image = img?.payload?._links?.content?.href;
        }
      });

    updateMetaTags(this.meta, this.title, data);
  }

  parseUrl(basePath: string): string {
    const protocol = window?.location?.protocol;
    const host = window?.location?.host;
    const fullUrl = `${protocol}//${host}${basePath}`;
    return fullUrl;
  }

  openModal() {
    this.modalIsOpen = true;
    document.body.classList.add('no-scroll');
  }

  closeModal() {
    this.modalIsOpen = false;
    document.body.classList.remove('no-scroll');
  }
}
