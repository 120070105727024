<div class="" style="margin-bottom: 15px; margin-top: 15px">
  <div class="container-btn">
    <button class="button" (click)="handleBack()">
      <img alt="flecha de regresar" src="../../assets/images/go-back-icon.svg" class="icon-back">
      <p class="text-back">Regresar</p>
    </button>
  </div>
  <section>
    <ds-item-navbar [object]="pdfData$" [image]="imageSrc" [showHeader]="true" [menuMode]="true" />
    <ds-pdf-view [url]="fileUrl" (openMenuEvent)="handleEvent()"></ds-pdf-view>
  </section>
  <div class="container-pdf">
    <ds-related-second-items [itemId]="itemId" [title]="'Contenido relacionado'"
      [route]="'read-item'"></ds-related-second-items>
  </div>
</div>