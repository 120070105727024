/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Injectable, PLATFORM_ID } from '@angular/core';
import { take } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
import { SignpostingDataService } from './signposting-data.service';
import { LinkDefinition, LinkHeadService } from '../services/link-head.service';
import { ServerResponseService } from '../services/server-response.service';
import { Inject } from '@angular/core';
import { SignpostingLink } from './signposting-links.model';
import { isNotEmpty } from 'src/app/shared/empty.util';
import { PdfService } from './pdf.service';

@Injectable({
  providedIn: 'root',
})
export class PageLinksService {
  signpostingLinks: SignpostingLink[] = [];
  constructor(
    private signpostingDataService: SignpostingDataService,
    private linkHeadService: LinkHeadService,
    private responseService: ServerResponseService,
    private pdfService: PdfService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  initPageLinks(itemId: string): void {
    this.signpostingDataService
      .getLinks(itemId)
      .pipe(take(1))
      .subscribe((signpostingLinks: SignpostingLink[]) => {
        let links = '';
        let linksItems = [];

        signpostingLinks.forEach((link: SignpostingLink) => {
          links =
            links +
            (isNotEmpty(links) ? ', ' : '') +
            `<${link.href}> ; rel="${link.rel}"` +
            (isNotEmpty(link.type) ? ` ; type="${link.type}" ` : ' ');

          let tag: LinkDefinition = {
            href: link.href,
            rel: link.rel,
          };

          if (isNotEmpty(link.type)) {
            tag = Object.assign(tag, {
              type: link.type,
            });
          }

          this.linkHeadService.addTag(tag);
          linksItems.push(link);
        });

        if (isPlatformServer(this.platformId)) {
          this.responseService.setHeader('Link', links);
        }

        this.setLinkItemIfPDF(linksItems);
      });
  }

  setLinkItemIfPDF(linksItems): void {
    if (linksItems) {
      linksItems.map((items) => {
        if (items.type === 'application/pdf') {
          this.pdfService.setPdfLink(items.href);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.signpostingLinks.forEach((link: SignpostingLink) => {
      this.linkHeadService.removeTag(`href='${link.href}'`);
    });
  }
}
