<div>
    <div *ngIf="isLoading" class="loader-container">
        <ds-small-loader [isLoading]="true"></ds-small-loader>
    </div>
    <div *ngIf="!isLoading" class="jumbotron jumbotron-fluid mt-ncs"
        style="background-image: url('{{dataItems?.image}}');">
        <div class="elementor-background-overlay"></div>
        <h1 class="title" [ngClass]="{'margin': !dataItems?.searchText}">
            {{dataItems?.title}}
        </h1>
        <div class="container">
            <h2 class="label">{{dataItems?.searchText}}</h2>
            <ds-themed-search-form [inPlaceSearch]="false"
                [searchPlaceholder]="dataItems?.placeholder !== null? dataItems?.placeholder: ''"></ds-themed-search-form>
        </div>
    </div>
</div>