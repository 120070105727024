import { Component, OnChanges, Input } from '@angular/core';
import { TypeDataItem } from './highlighted-sections.model';

@Component({
  selector: 'ds-highlighted-sections',
  templateUrl: './highlighted-sections.component.html',
  styleUrls: ['./highlighted-sections.component.scss'],
})
export class HighlightedSectionsComponent implements OnChanges {
  dataItems: TypeDataItem = {};
  @Input() data;
  @Input() isLoading;

  ngOnChanges(): void {
    this.dataItems = this.data;
  }
}
