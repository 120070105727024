import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { hasValue } from '../shared/empty.util';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { NotificationsService } from '../shared/notifications/notifications.service';
import { handleDownload } from '../shared/utils/download-book.util';
@Component({
  selector: 'ds-pdf-view',
  styleUrls: ['./pdf-view.component.scss'],
  templateUrl: './pdf-view.component.html',
})
export class PdfViewComponent implements OnInit {
  @Input() url;
  isLoading = true;
  page = 1;
  numPages = 0;
  zoomValue = 1.0;
  rotation = 0;
  results = 0;
  pdfQuery = '';
  openModal: boolean;
  showMenu = false;

  @Output() openMenuEvent = new EventEmitter<void>();

  constructor(
    public der: ChangeDetectorRef,
    public notify: NotificationsService
  ) {}

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChild(PdfViewerComponent, { static: false })
  private pdfComponent: PdfViewerComponent;

  ngOnInit(): void {
    this.openModal = false;
  }

  IsLoading(event) {
    if (hasValue(event)) {
      this.numPages = event.numPages;
      this.isLoading = event;
      this.isLoading = false;
    }
  }
  openMenu() {
    this.openMenuEvent.emit();
    if (this.showMenu) {
      this.showMenu = false;
    }
  }

  handleShowMenu() {
    this.showMenu = !this.showMenu;
  }
  handleClear() {
    let selector = document.getElementsByClassName(
      'ng2-pdf-viewer-container'
    )[0];
    if (this.pdfQuery !== '') {
      this.pdfQuery = '';
      this.results = 0;
      selector.scrollTo(0, 0);
      this.searchQueryChanged('');
      this.der.detectChanges();
    }
  }

  handleClickZoom(amount: number) {
    this.zoomValue += amount;
  }

  handleRotate(direction: number) {
    this.rotation =
      direction === 0 ? (this.rotation + 90) % 360 : (this.rotation - 90) % 360;
    if (this.pdfComponent.pdfViewer) {
      this.pdfComponent.pdfViewer.pagesRotation = this.rotation;
    }
  }

  handleModal() {
    let selector = document.getElementsByClassName(
      'ng2-pdf-viewer-container'
    )[0];
    if (this.openModal && this.pdfQuery !== '') {
      this.pdfQuery = '';
      this.results = 0;
      selector.scrollTo(0, 0);
    }
    this.openModal = !this.openModal;
  }

  handleincrementPage(amount: number) {
    this.page += amount;
    if (this.page === 0) {
      this.page = 1;
    }
  }

  download() {
    let result = handleDownload(this.url);
    this.notify.info(result);
  }

  searchQueryChanged(newQuery: string) {
    if (newQuery !== '') {
      const type = newQuery !== this.pdfQuery ? '' : 'again';
      this.pdfQuery = newQuery;
      this.pdfComponent.pdfViewer.eventBus.on(
        'updatefindmatchescount',
        (data) => {
          this.results = data.matchesCount.total;
        }
      );
      this.pdfComponent.eventBus.dispatch('find', {
        type,
        query: newQuery,
        highlightAll: true,
        caseSensitive: false,
        phraseSearch: true,
      });
    } else {
      this.pdfComponent.eventBus.dispatch('find', {
        query: newQuery,
        highlightAll: false,
        caseSensitive: false,
        phraseSearch: true,
      });
      this.results = 0;
      let selector = document.getElementsByClassName(
        'ng2-pdf-viewer-container'
      )[0];
      selector.scrollTo(0, 0);
    }
  }
}
