import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TypeDataItems } from './recent-news.model';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'ds-recent-news',
  templateUrl: './recent-news.component.html',
  styleUrls: ['./recent-news.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RecentNewsComponent {
  @Input() data;
  @Input() isLoading;
  dataItems: TypeDataItems;

  ngOnChanges(): void {
    this.dataItems = this.data;
  }

  formatDate(date: string): string {
    const newDate = date.split('-').reverse().join('-');
    return newDate;
  }

  getNewsLink(title: string): string {
    let link = '/news/';
    link += title.trim().split(' ').join('-').toLowerCase();
    return link;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  breakpoints: Record<number, { slidesPerView: number }> = {
    640: {
      slidesPerView: 1,
    },
    850: {
      slidesPerView: 2,
    },
    1250: {
      slidesPerView: 3,
    },
  };
}
