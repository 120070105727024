<section class="elementor-section">
    <div *ngIf="isLoading" class="loader-container">
        <ds-small-loader [isLoading]="true"></ds-small-loader>
    </div>
    <div *ngIf="!isLoading">
        <div *ngIf="dataItems?.image" class="elementor-background-overlay"
            style="background-image: url('{{dataItems.image}}')"></div>
        <div class="elementor-container">
            <div class="elementor-column">
                <div class="elementor-widget-wrap">
                    <div class="elementor-element ">
                        <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title">
                                <strong>{{dataItems?.title}}</strong>
                            </h2>
                        </div>
                    </div>
                    <section>
                        <div>
                            <article *ngFor="let item of dataItems.servicios">
                                <a [href]="item.href" target="_blank">
                                    <picture>
                                        <img *ngIf="item.image" [src]="item.image">
                                    </picture>
                                    <h3>{{item?.title}}</h3>
                                    <p>{{item?.description}}</p>
                                </a>
                            </article>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</section>