/* eslint-disable @typescript-eslint/no-floating-promises */
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  constructor(private router: Router) {}

  handleScroll() {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }

  scrollToSection(sectionId: string) {
    const isHome = this.router.url === '/';
    if (isHome) {
      this.scrollTo(sectionId);
    } else {
      this.router.navigate(['/']).then(() => {
        setTimeout(() => {
          this.scrollTo(sectionId);
        }, 100);
      });
    }
  }

  public scrollTo(sectionId: string) {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
