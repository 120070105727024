import { Item } from '../../../../core/shared/item.model';
import { ItemComponent } from '../shared/item.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewMode } from '../../../../core/shared/view-mode.model';
import { RouteService } from 'src/app/core/services/route.service';
import { PageLinksService } from 'src/app/core/data/page-links.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { listableObjectComponent } from '../../../../shared/object-collection/shared/listable-object/listable-object.decorator';
/**
 * Component that represents a publication Item page
 */

@listableObjectComponent(Item, ViewMode.StandalonePage)
@Component({
  selector: 'ds-untyped-item',
  styleUrls: ['./untyped-item.component.scss'],
  templateUrl: './untyped-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UntypedItemComponent extends ItemComponent implements OnInit {
  itemId: string;
  constructor(
    public routeService: RouteService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    public pageLinkService: PageLinksService,
    public der: ChangeDetectorRef
  ) {
    super(routeService, router);
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params.id !== null && params.id !== undefined) {
        this.itemId = params?.id;
        this.pageLinkService.initPageLinks(params?.id);
        this.der.markForCheck();
      }
    });
  }
}
