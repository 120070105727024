import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper';
import { TypeDataSlides } from './carousel.model';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

@Component({
  selector: 'ds-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CarouselComponent implements OnInit {
  dataCarousel = [];

  @Input() slides: TypeDataSlides[];
  @Input() navigation = false;
  @Input() loop = false;
  @Input() pagination = false;
  @Input() scrollbar = false;
  @Input() delay = 0;
  @Input() disableOnInteraction = false;
  @Input() breakpoints: Record<number, { slidesPerView: number }>;

  ngOnInit(): void {
    if (this.slides?.length > 0) {
      this.dataCarousel = this.slides;
    }
  }
}
