<div class="container-general">
  <div style="position: absolute; top: 0; right: 0;">
    <ds-dso-edit-menu></ds-dso-edit-menu>
  </div>
  <div class="lef-container">
    <div class="container-image">
      <ds-image-center (loadingStatus)="onLoadingStatusChanged($event)" [thumbnail]="object?.thumbnail | async"
        [limitWidth]="false"></ds-image-center>
    </div>
  </div>
  <footer class="container-info">
    <div class="primary-info-container">
      <article *ngFor="let item of extractedData?.primaryData">
        <h5>{{item?.label}}</h5>
        <ds-truncatable [id]="''">
          <ds-truncatable-part [id]="''" [minLines]="4">
            <p class="item-abstract card-text">
              <span [innerHTML]="item?.value"></span>
            </p>
          </ds-truncatable-part>
        </ds-truncatable>
      </article>
    </div>
    <div class="footer-container">
      <div class="footer-sub-container">
        <div *ngFor="let items of extractedData?.secondData">
          <h5>{{items?.label}}</h5>
          <span>{{items?.value === 'es' ? 'Español': items?.value === 'en' ? 'Inglés': items?.value}}</span>
        </div>
      </div>
    </div>
    <div *ngIf="extractedData?.secondData?.length" class="footer-container-mobile">
      <div class="footer-sub-container">
        <div *ngFor="let items of extractedData?.secondData">
          <h5>{{items?.label}}</h5>
          <span>{{items?.value === 'es' ? 'Español': items?.value === 'en' ? 'Inglés': items?.value}}</span>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoadingImage && showButtons && object" class="container-share-btn">
      <section class="button-container">
        <button class="read-button" (click)="onReadClick()">
          <img class="doc" src="../../../../../assets/images/t.svg" />
          <span>
            {{ 'item.navbar.read.pdf' | translate}}
          </span>
        </button>
        <button class="download-button" (click)="downloadBook()">
          <img class="download-icon" src="../../../../../assets/images/download.svg" />
          <span>
            {{ 'item.navbar.download' | translate}}
          </span>
        </button>
        <button class="share-button" (click)="openModal()">
          <img class="share" src="../../assets/images/icons8-share.png" />
          <span>
            Compartir
          </span>
        </button>
      </section>
      <ds-share-item [isOpen]="modalIsOpen" [documentTitle]="documentTitle" [documentUrl]="documentUrl"
        (close)="closeModal()"></ds-share-item>
    </div>
  </footer>
</div>