import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NotificationsService } from '../shared/notifications/notifications.service';

@Component({
  selector: 'ds-share-item',
  styleUrls: ['./share-item.component.scss'],
  templateUrl: 'share-item.component.html',
})
/**
 * Component responsible to share link
 */
export class ShareItemComponent {
  stateButton = false;
  @Input() isOpen = false;
  @Input() documentTitle;
  @Input() documentUrl = '';
  @Output() close = new EventEmitter<void>();

  constructor(
    protected notification: NotificationsService,
    private cdr: ChangeDetectorRef
  ) {}
  // Copy url item
  copyTextToClipboard(): any {
    if (!navigator.clipboard || !this.documentUrl) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigator.clipboard.writeText(this.documentUrl).then((event) => {
      if (!this.stateButton) {
        this.stateButton = true;
        this.cdr.detectChanges();
      }
    });
    setTimeout(() => {
      this.stateButton = false;
      this.cdr.detectChanges();
    }, 3000);
  }

  isWebShareSupported(): boolean {
    return !!navigator.share;
  }

  openUrl(url: string) {
    window.open(url, '_blank');
    this.closeModal();
  }
  // Handle close modal
  closeModal() {
    this.isOpen = false;
    this.close.emit();
  }

  // Shared method
  share() {
    if (navigator.share) {
      navigator
        .share({
          url: this.documentUrl,
        })
        .catch((error) => {
          console.error('Error al compartir', error);
        });
      this.closeModal();
    }
  }
}
