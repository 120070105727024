<section class="elementor-section-news">
    <div class="elementor-background-overlay" style="background-image: url('{{dataItems?.image}}');"></div>
    <div class="elementor-container">
        <div class="elementor-column">
            <div *ngIf="isLoading" class="loader-container">
                <ds-small-loader [isLoading]="true"></ds-small-loader>
            </div>
            <div *ngIf="!isLoading" class="elementor-widget-wrap">
                <div class="elementor-element">
                    <div class="elementor-widget-container">
                        <h2 class="elementor-heading-title">
                            <strong>{{data?.title}}</strong>
                        </h2>
                    </div>
                </div>
                <section *ngIf="dataItems?.items?.length" class="elementor-items">
                    <div>
                        <swiper class="recentNewsCarousel" [navigation]="true" [scrollbar]="{ draggable: true }"
                            [loop]="false" [pagination]="{ clickable: true }" [breakpoints]="breakpoints">
                            <ng-template swiperSlide *ngFor="let item of dataItems.items">
                                <article class="recentNews-carousel-item">
                                    <header>
                                        <a [routerLink]="[getNewsLink(item.title)]">
                                            <picture>
                                                <img [src]="item.srcImg">
                                            </picture>
                                            <h3>{{item.title}}</h3>
                                        </a>
                                    </header>
                                    <span>{{formatDate(item.date)}}</span>
                                    <a [routerLink]="[getNewsLink(item.title)]">
                                        LEER MÁS
                                    </a>
                                </article>
                            </ng-template>
                        </swiper>
                    </div>
                </section>
                <ng-template #noData>
                    <h3 class="no-data">
                        Lo sentimos, no hay noticias disponibles en este momento.
                    </h3>
                </ng-template>
            </div>
        </div>
    </div>
</section>