import { Observable } from 'rxjs';
import { Component, Input, OnChanges } from '@angular/core';
import { MenuID } from '../shared/menu/menu-id.model';
import { MenuService } from '../shared/menu/menu.service';
import { HostWindowService } from '../shared/host-window.service';
import { hasValue } from '../shared/empty.util';
import { isAuthenticated } from '../core/auth/selectors';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.reducer';

@Component({
  selector: 'ds-header',
  styleUrls: ['header.component.scss'],
  templateUrl: 'header.component.html',
})
export class HeaderComponent implements OnChanges {
  /**
   * Whether user is authenticated.
   * @type {Observable<string>}
   */
  @Input() data;
  @Input() infoUser;
  dataHeader;
  public menuCollapsed: Observable<boolean>;
  public iconMenu = false;
  public isAuthenticated$: Observable<boolean>;
  public isXsOrSm$: Observable<boolean>;
  menuID = MenuID.PUBLIC;

  constructor(
    private store: Store<AppState>,
    protected menuService: MenuService,
    protected windowService: HostWindowService
  ) {}

  ngOnChanges(): void {
    if (hasValue(this.data)) {
      this.dataHeader = this.data;
    }
    this.isXsOrSm$ = this.windowService.isXsOrSm();
    this.menuCollapsed = this.menuService.isMenuCollapsed(this.menuID);

    this.isAuthenticated$ = this.store.pipe(select(isAuthenticated));
  }

  getStateNavbar() {
    return this.menuService.getStateNavbar();
  }

  public toggleNavbar(): void {
    this.menuService.toggleNavbar();
  }
}
