import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'ds-links-interest',
  templateUrl: './links-interest.component.html',
  styleUrls: ['./links-interest.component.scss'],
})
export class LinksInterestComponent implements OnChanges {
  @Input() data;
  @Input() isLoading;
  dataSection;

  breakpoints: Record<number, { slidesPerView: number }> = {
    640: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
    1100: {
      slidesPerView: 3,
    },
  };
  ngOnChanges(): void {
    this.dataSection = this.data;
  }
}
