<swiper *ngIf="dataCarousel.length" class="swiper-carousel" [navigation]="navigation" [loop]="loop"
    [pagination]="{ clickable: pagination }" [scrollbar]="{ draggable: scrollbar }" [breakpoints]="breakpoints"
    [autoplay]="{
        delay: delay,
        disableOnInteraction: disableOnInteraction
    }">
    <ng-template swiperSlide *ngFor="let slide of slides">
        <a class="slider-anchor" [href]="slide?.href" target="_blank">
            <img [src]="slide?.srcImg">
        </a>
    </ng-template>
</swiper>