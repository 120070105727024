import { ActivatedRoute } from '@angular/router';
import { NewsService } from '../core/services/news.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss'],
})
export class NewsDetailComponent implements OnInit {
  dataItem;
  constructor(
    private newService: NewsService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.newService.getSectionItems(params.get('title')).subscribe((data) => {
        this.dataItem = data;
        this.cdr.detectChanges();
      });
    });
  }

  getDate(date: string): string {
    if (!date) {
      return '';
    }
    const newDate = date.split('-').reverse().join('-');
    return newDate;
  }

  getDescription(descriptionObject): string {
    let description = '';
    let initialTags = '';
    let finalsTags = '';

    descriptionObject.forEach((el) => {
      if (el.type === 'link') {
        initialTags = `<a href="${el.url}" Target="_blank">`;
        finalsTags = '</a>';
        el = el.children[0];
      }
      if (el.type === 'text') {
        initialTags = '';
        finalsTags = '';

        if (el.bold) {
          initialTags += '<b>';
          finalsTags = '</b>' + finalsTags;
        }
        if (el.italic) {
          initialTags += '<i>';
          finalsTags = '</i>' + finalsTags;
        }
        if (el.underline) {
          initialTags += '<u>';
          finalsTags = '</u>' + finalsTags;
        }
        if (el.strikethrough) {
          initialTags += '<s>';
          finalsTags = '</s>' + finalsTags;
        }
        if (el.code) {
          initialTags += '<code>';
          finalsTags = '</code>' + finalsTags;
        }
        description += initialTags + el.text + finalsTags;
      }
    });
    return description;
  }
}
