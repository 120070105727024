<section class="elementor-section" [ngClass]="{'spacing': isLoading}">
    <div *ngIf="isLoading" class="loader-container">
        <ds-small-loader [isLoading]="true"></ds-small-loader>
    </div>
    <div *ngIf="dataItems?.image && !isLoading" class="elementor-background-overlay"
        style="background-image: url('{{dataItems.image}}');"></div>
    <div *ngIf="!isLoading" class="elementor-container">
        <div class="elementor-column">
            <div class="elementor-widget-wrap">
                <div class="elementor-element">
                    <div class="elementor-widget-container">
                        <h2 class="elementor-heading-title">
                            <strong>
                                {{dataItems?.title}}
                            </strong>
                        </h2>
                    </div>
                </div>
                <section class="elementor-section-section">
                    <div *ngIf="dataItems?.items?.length" class="elementor-container">
                        <div class="elementor-column" *ngFor="let item of dataItems.items">
                            <div class="elementor-widget-wrap">
                                <div class="elementor-element">
                                    <div class="elementor-widget-container">
                                        <div class="bdt-ep-advanced-icon-box">
                                            <div class="bdt-ep-advanced-icon-box-icon">
                                                <span class="bdt-ep-advanced-icon-box-icon-wrap">
                                                    <img [src]="item?.srcImg">
                                                </span>
                                            </div>
                                            <div class="bdt-ep-advanced-icon-box-content">
                                                <h3 class="bdt-ep-advanced-icon-box-title">
                                                    <span>{{item?.title}}</span>
                                                </h3>
                                                <div class="bdt-ep-advanced-icon-box-description">
                                                    <p>{{item?.description}}</p>
                                                </div>
                                                <a *ngIf="item?.textButton" class="bdt-ep-advanced-icon-box-readmore"
                                                    [href]="item?.href" target="_blank">
                                                    {{item?.textButton}}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>