<button (click)="skipToMainContent()" class="btn btn-primary" id="skip-to-main-content">
  {{ 'root.skip-to-content' | translate }}
</button>

<div class="outer-wrapper" [class.d-none]="shouldShowFullscreenLoader" [@slideSidebarPadding]="{
     value: (!(sidebarVisible | async) ? 'hidden' : (slideSidebarOver | async) ? 'shown' : 'expanded'),
     params: {collapsedSidebarWidth: (collapsedSidebarWidth | async), totalSidebarWidth: (totalSidebarWidth | async)}
    }">
  <ds-themed-admin-sidebar></ds-themed-admin-sidebar>
  <div class="inner-wrapper" [ngClass]="{'apply-margin': (sidebarVisible | async)}">
    <div #wrapper>
      <ds-system-wide-alert-banner></ds-system-wide-alert-banner>
      <ds-themed-header-navbar-wrapper [headerData]="headerData"
        [infoUser]="infoUser"></ds-themed-header-navbar-wrapper>
    </div>
    <div #dir>
      <ds-themed-breadcrumbs></ds-themed-breadcrumbs>
    </div>
    <main class="" id="main-content">
      <!--my-cs-->

      <div class="container d-flex justify-content-center align-items-center h-100" *ngIf="shouldShowRouteLoader">
        <div class="loader-container-home">
          <div class="spinner"></div>
          <img loading="lazy" src="https://i.postimg.cc/ncYf4snJ/Logo-Poder-Judicial-3x-1.png" alt="Logo" class="logo">
        </div>
        <!--<ds-themed-loading [showMessage]="false"></ds-themed-loading>-->
      </div>
      <div [class.d-none]="shouldShowRouteLoader">
        <router-outlet></router-outlet>
      </div>
    </main>

    <div #footer>
      <ds-themed-footer></ds-themed-footer>
    </div>
  </div>
</div>

<ds-notifications-board [options]="notificationOptions">
</ds-notifications-board>

<div class="ds-full-screen-loader" *ngIf="shouldShowFullscreenLoader">
  <div class="loader-container-home">
    <div class="spinner"></div>
    <img loading="lazy" src="../../assets/images/Logo-Poder-Judicial@3x-1.png" alt="Logo" class="logo">
  </div>
  <!--<ds-themed-loading [showMessage]="false"></ds-themed-loading>-->
</div>