import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import axios from 'axios';
import { PdfService } from 'src/app/core/data/pdf.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { handleDownload } from 'src/app/shared/utils/download-book.util';
@Component({
  selector: 'ds-item-navbar',
  templateUrl: './item-navbar.component.html',
  styleUrls: ['./item-navbar.component.scss'],
})
export class ItemNavbarComponent implements OnChanges {
  id: string;
  imageSrc: string;
  @Input() object: any;
  @Input() image: string;
  showMenu = false;
  hasError = false;
  isLoadingImage = true;
  @Input() showHeader = true;
  @Input() menuMode = false;

  constructor(
    public router: Router,
    public pdfService: PdfService,
    public der: ChangeDetectorRef,
    public activeRoute: ActivatedRoute,
    public notify: NotificationsService
  ) {
    this.activeRoute.params.subscribe((params) => {
      this.id = params?.id;
    });
  }

  ngOnChanges() {
    if (this.image) {
      this.imageSrc = this.image;
      setTimeout(() => {
        this.isLoadingImage = false;
      }, 1000);
      this.der.detectChanges();
    } else {
      this.imageSrc = undefined;
      this.isLoadingImage = false;
      this.der.detectChanges();
    }
  }

  onReadClick() {
    this.router.navigate([`/read-item/${this.id}`]);
  }

  downloadBook() {
    let url;
    this.pdfService.getLink().subscribe((data) => {
      url = data;
    });
    let result = handleDownload(url);
    this.notify.info(result);
  }

  handleShowMenu() {
    this.showMenu = !this.showMenu;
  }

  onImageLoad(): void {
    this.isLoadingImage = false;
    this.hasError = false;
    this.der.detectChanges();
  }

  onImageError(): void {
    this.isLoadingImage = false;
    this.hasError = true;
    this.der.detectChanges();
  }
}
