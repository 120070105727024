<nav class="navbar-content" [ngClass]="{'open': !getStateNavbar()}"
    [@slideMobileNav]="!(windowService.isXsOrSm() | async) ? 'default' : (getStateNavbar() ? 'collapsed' : 'expanded')">
    <div class="e-con-inner">
        <div class="elementor-widget-container">
            <div class="elementor-nav-menu-main" id="collapsingNav">
                <ul class="elementor-nav-menu">
                    <li *ngFor="let section of navbarLinks" class="menu-item">
                        <a (click)="toggleNavbar()" *ngIf=" section?.targetBlank; else internalLink"
                            [attr.target]="'_blank'" [href]="section?.href" class="elementor-item">
                            {{ section.label }}
                        </a>
                        <ng-template #internalLink>
                            <a *ngIf="section?.href?.startsWith('/'); else scrollLink" (click)="toggleNavbar()"
                                [routerLink]="section?.href" class="elementor-item" routerLinkActive="active-link"
                                [routerLinkActiveOptions]="{ exact: true }">
                                {{ section?.label }}
                            </a>
                            <ng-template #scrollLink>
                                <a (click)="handleScroll(section?.href)" class="elementor-item">
                                    {{ section?.label }}
                                </a>
                            </ng-template>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div class="container-access">
                <a (click)="toggleNavbar()" *ngIf="!(isAuthenticated$ | async)" routerLink={{navbarData?.login}}
                    class="elementor-item-access">
                    <span>
                        <i class="fas fa-sign-in-alt" style="margin-right: 5px"></i>
                        {{navbarData?.accessText}}
                    </span>
                </a>
                <ds-avatar *ngIf="(isAuthenticated$ | async)" [user]="user" [showName]="true"></ds-avatar>
            </div>
        </div>
    </div>
</nav>