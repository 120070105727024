<section *ngIf="dataItem">
    <header>
        <picture>
            <img [src]="dataItem?.srcImg">
        </picture>
        <div>
            <h1>{{dataItem?.title}}</h1>
            <div>
                <i aria-hidden="true" class="fas fa-calendar"></i>
                <span>{{getDate(dataItem?.date)}}</span>
            </div>
        </div>
    </header>
    <div>
        <p *ngFor="let description of dataItem.description" [innerHtml]="getDescription(description.children)">
        </p>
    </div>
</section>