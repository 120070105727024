import {
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { hasValue } from '../shared/empty.util';
import { Observable, Subscription } from 'rxjs';
import { MenuID } from '../shared/menu/menu-id.model';
import { MenuService } from '../shared/menu/menu.service';

/**
 * This component represents a wrapper for the horizontal navbar and the header
 */
@Component({
  selector: 'ds-header-navbar-wrapper',
  styleUrls: ['header-navbar-wrapper.component.scss'],
  templateUrl: 'header-navbar-wrapper.component.html',
})
export class HeaderNavbarWrapperComponent implements OnInit, OnDestroy {
  dataHeader;
  @Input() headerData;
  @Input() infoUser;
  @HostBinding('class.open') isOpen = false;
  private sub: Subscription;
  public isNavBarCollapsed: Observable<boolean>;
  menuID = MenuID.PUBLIC;

  constructor(private menuService: MenuService) {}

  ngOnInit(): void {
    if (this.headerData) {
      this.headerData.subscribe((dataRD) => {
        this.dataHeader = dataRD;
      });
    }
    this.isNavBarCollapsed = this.menuService.isMenuCollapsed(this.menuID);
    this.sub = this.isNavBarCollapsed.subscribe(
      (isCollapsed) => (this.isOpen = !isCollapsed)
    );
  }

  ngOnDestroy() {
    if (hasValue(this.sub)) {
      this.sub.unsubscribe();
    }
  }
}
