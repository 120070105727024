import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'ds-frequent-services',
  templateUrl: './frequent-services.component.html',
  styleUrls: ['./frequent-services.component.scss'],
})
export class FrequentServicesComponent implements OnChanges {
  @Input() data;
  @Input() isLoading;
  dataItems: any;

  ngOnChanges(): void {
    this.dataItems = this.data;
  }
}
