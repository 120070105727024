<main class="register-main-form">
  <p *ngIf="validMailDomains.length != 0 && typeRequest === TYPE_REQUEST_REGISTER">
    {{ MESSAGE_PREFIX + '.info.maildomain' | translate}} {{ validMailDomains.join(', ')}}
  </p>
  <section>
    <header>
      <img src="assets/images/logo-pj.svg" alt="Poder Judicial - Acceso Digital">
      <div>
        <!--<h2>
          Realice trámites, lea artículos y participe en nuestra comunidad judicial de la República
          Dominicana.
        </h2>-->
      </div>
    </header>
    <section>
      <div>
        <img src="assets/images/icon-arrow-right.svg">
        <h3>{{MESSAGE_PREFIX + '.header'|translate}}</h3>
        <div>
          <form [class]="'ng-invalid'" [formGroup]="form">
            <div>
              <label for="email">
                {{"login.form.email" | translate}} <span>*</span>
              </label>
              <div>
                <input autofocus type="text" id="email" required tabindex="1"
                  placeholder="{{'login.form.email' | translate}}" required type="email" formControlName="email"
                  [className]="(email.invalid) && (email.dirty || email.touched) ? 'form-control is-invalid' :'form-control'" />
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback show-feedback">
                  <span *ngIf="email.errors && email.errors.required">
                    {{ MESSAGE_PREFIX + '.email.error.required' | translate }}
                  </span>
                  <span
                    *ngIf="email.errors && ((email.errors.pattern && this.typeRequest === TYPE_REQUEST_REGISTER) || email.errors.email)">
                    {{ MESSAGE_PREFIX + '.email.error.not-email-form' | translate }}
                    <ng-container *ngIf="validMailDomains.length > 0">
                      {{ MESSAGE_PREFIX + '.email.error.not-valid-domain' | translate: { domains: validMailDomains.join(', ') } }}
                    </ng-container>
                  </span>
                </div>
                <!--<label *ngIf="!email.errors && !((email.errors.pattern && this.typeRequest === TYPE_REQUEST_REGISTER) || email.errors.email)" for="email">
                  <span class="fa-regular fa-user"></span>
                </label>-->
              </div>
            </div>
            <section>
              <ng-container
                *ngIf="!((googleRecaptchaService.captchaVersion() | async) === 'v2' && (googleRecaptchaService.captchaMode() | async) === 'invisible'); else v2Invisible">
                <button
                  [disabled]="form.invalid || registrationVerification && !isRecaptchaCookieAccepted() || disableUntilChecked"
                  (click)="register()">
                  {{ MESSAGE_PREFIX + '.submit' | translate }}
                </button>
              </ng-container>

              <ng-template #v2Invisible>
                <button [disabled]="form.invalid" (click)="executeRecaptcha()">
                  {{ MESSAGE_PREFIX + '.submit' | translate }}
                </button>
              </ng-template>
            </section>
          </form>
          <div>
            <div>
              <a routerLink="/login">
                ¿Ya tiene una cuenta?
                <span>Iniciar sesión</span>
              </a>
              <a routerLink="/home">
                <span>Continuar sin cuenta</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>

</main>