import { Component, Input, OnChanges } from '@angular/core';
@Component({
  selector: 'ds-section-search',
  templateUrl: './section-search.component.html',
  styleUrls: ['./section-search.component.scss'],
})
export class SectionSearchComponent implements OnChanges {
  @Input() data;
  @Input() isLoading;
  dataItems: any;

  ngOnChanges(): void {
    this.dataItems = this.data;
  }
}
