import { Injectable } from '@angular/core';
import axios, { AxiosResponse } from 'axios';
import { catchError, from, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  private apiUrl = `${environment?.rest?.strapiUrl}/api/noticias?populate[Seccion][populate][Imagen]=*&populate[Seccion][populate][Noticias][populate][Imagen]=*`;

  getSectionItems(title: string): Observable<any> {
    return from(this.fetchSectionData(title)).pipe(
      catchError((error) => {
        return of({});
      })
    );
  }

  private async fetchSectionData(title: string): Promise<any> {
    try {
      title = title.split('-').join(' ');
      const response: AxiosResponse = await axios.get(this.apiUrl);
      const attributes = response?.data?.data[0]?.attributes.Seccion;
      const item = attributes?.Noticias?.filter(
        (el) => title === el?.Titulo.toLowerCase().trim()
      ).map((el) => {
        return {
          description: el?.Descripcion,
          title: el?.Titulo,
          srcImg: this.getImage(el?.Imagen.data),
          date: el?.Fecha,
        };
      });
      return item[0];
    } catch (error) {
      console.error('Error in fetchData:');
      throw error;
    }
  }

  getImage(data) {
    if (data) {
      return data?.attributes?.url;
    }
    return '';
  }
}
