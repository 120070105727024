<div class="site-footer">
  <div class="elementor elementor-144 elementor-location-footer">
    <section
      class="elementor-section elementor-top-section elementor-element elementor-element-5ffa330 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default">
      <div class="elementor-container elementor-column-gap-default">
        <div
          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-66df11b">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element-container elementor-element-a42e069 elementor-widget__width-initial elementor-absolute elementor-align-center elementor-widget elementor-widget-bdt-scroll-button">
              <div class="elementor-button-container">
                <div class="bdt-scroll-button-wrapper">
                  <button (click)="this.scroll.handleScroll()"
                    class="bdt-scroll-button bdt-button bdt-button-primary elementor-animation-grow"><span
                      class="bdt-scroll-button-content-wrapper">
                      <span class="bdt-scroll-button-align-icon-right">
                        <span class="arrow-up-icon fa-solid fa-arrow-up"></span>
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <section
              class="elementor-section elementor-inner-section elementor-element elementor-element-022e0bf elementor-section-boxed elementor-section-height-default elementor-section-height-default">
              <div class="elementor-container elementor-column-gap-default">
                <div
                  class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-7464ec3">
                  <div class="elementor-widget-wrap elementor-widget-wrap-container elementor-column-populated">
                    <div
                      class="elementor-element elementor-element-container-img elementor-element-e428389 elementor-widget-image">
                      <div class="elementor-img-container">
                        <img width="310" height="87" alt="Logo Poder Judicial"
                          class="attachment-full size-full wp-image-29368 lazy loaded"
                          src="../../assets/images/logo-poder-judicial.png" sizes="(max-width: 500px) 100vw, 500px">
                      </div>
                    </div>
                    <div class="elementor-element elementor-element-a4fd5c7 elementor-widget elementor-widget-html">
                      <div class="elementor-widget-container">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              class="elementor-section elementor-inner-section elementor-element elementor-element-db2a29e elementor-section-boxed elementor-section-height-default elementor-section-height-default">
              <div class="elementor-container elementor-column-gap-default">
                <div
                  class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-5752c6d"
                  data-id="5752c6d" data-element_type="column">
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div *ngFor="let text of dataFooter.texts"
                      class="elementor-element elementor-widget elementor-widget-heading">
                      <div class="elementor-widget-container">
                        <p class="elementor-heading-title elementor-size-small">
                          {{text}}
                        </p>
                      </div>
                    </div>
                    <div class="elementor-element elementor-element-ff77cc1 elementor-widget elementor-widget-heading">
                      <div class="elementor-widget-container">
                        <p class="elementor-heading-title elementor-size-small">Tel: {{dataFooter.telephone}} | Email:
                          <a class="elementor-heading-title-link"
                            href="mailto:{{dataFooter.email}}">{{dataFooter.email}}</a>
                        </p>
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-2ebb917 elementor-nav-menu__align-center elementor-nav-menu--dropdown-none elementor-widget elementor-widget-nav-menu">
                      <div class="elementor-widget-container">
                        <nav
                          class="elementor-nav-menu--main elementor-nav-menu_container elementor-nav-menu--layout-horizontal e--pointer-text e--animation-none">
                          <ul id="menu-1-2ebb917" class="elementor-nav-menu">
                            <li *ngFor="let link of dataFooter.links"
                              class="menu-item menu-item-type-post_type menu-item-object-page">
                              <a class="elementor-item">{{link.title}}</a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-80133aa elementor-hidden-desktop elementor-widget elementor-widget-heading">
                      <div class="elementor-widget-container">
                        <span class="elementor-heading-title elementor-heading-title-web elementor-size-small">
                          {{dataFooter.certification}}
                        </span>
                      </div>
                    </div>
                    <div class="elementor-element elementor-element-ced2f4a elementor-widget elementor-widget-heading">
                      <div class="elementor-widget-container elementor-container-widget">
                        <span class="elementor-heading-title elementor-size-small">© {{dataFooter.year}}
                          {{dataFooter.copyright}}
                        </span>
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-logo elementor-element-widget-container elementor-element-c3575c2 elementor-hidden-mobile elementor-widget elementor-widget-html">
                      <div class="elementor-widget-container">
                        <div class="elementor-widget-container">
                          <div class="stamp-container">
                            <div *ngFor="let img of dataFooter.icons.imgs" class="stamp-box">
                              <div class="stamp">
                                <a [href]="dataFooter.icons.href" target="_blank" class="stamp stack-top"
                                  [title]="img.title">
                                </a>
                                <iframe [src]="img.src" height="85px" width="85px" frameborder="0" scrolling="no"
                                  title="be-nortic-ogtic-gob">
                                </iframe>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="elementor-element elementor-element-dd77110 elementor-widget elementor-widget-html">
              <div class="elementor-widget-container">
                <hr class="igualandoline">
                <div class="igualando">
                  <img alt="Igualando RD. Sello de Igualdad de Género" title="Igualando RD. Sello de Igualdad de Género"
                    class="lazy loaded logo-img"
                    src="../../assets/images/logo-Igualando-RD-Oro.png">
                </div>
                <style>
                </style>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>