<main>
  <section>
    <header>
      <img src="assets/images/logo-pj.svg" alt="Poder Judicial - Acceso Digital">
      <div>
        <!--<h2>
          Realice trámites, lea artículos y participe en nuestra comunidad judicial de la República
          Dominicana.
        </h2>-->
      </div>
    </header>
    <section>
      <div>
        <img src="assets/images/icon-arrow-right.svg">
        <h3>Iniciar sesión</h3>
        <div>
          <form (ngSubmit)="submit()" [formGroup]="form" novalidate>
            <div>
              <label for="email">
                Correo electrónico <span>*</span>
              </label>
              <div>
                <input [attr.aria-label]="'Correo'" autofocus id="email" required tabindex="1"
                  formControlName="email" placeholder="{{'Correo electrónico' | translate}}" required type="email"
                  [attr.data-test]="'email' | dsBrowserOnly">
                <label for="email">
                  <span class="fa-regular fa-user"></span>
                </label>
              </div>
            </div>
            <div>
              <label for="password">
                Contraseña  <span>*</span>
              </label>
              <div>
                <input [type]="showPassword ? 'text' : 'password'" id="password" placeholder="Contraseña" required=""
                  tabindex="2" [attr.aria-label]="'login.form.password' | translate" autocomplete="off"
                  placeholder="{{'Contraseña '}}" formControlName="password" required
                  [attr.data-test]="'password' | dsBrowserOnly">
                <label for="password">
                  <span [class]="showPassword ? 'fa-solid fa-eye-slash':'fa-solid fa-eye'"
                    (click)="toggleShowPassword()"></span>
                </label>
              </div>
            </div>
            <section>
              <div>
                <a routerLink="/forgot">{{ '¿Has olvidado tu contraseña?' }}</a>
              </div>
              <button type="submit" [attr.data-test]="'login-button' | dsBrowserOnly"
                [disabled]="!form.valid">{{"Acceso" }}</button>
            </section>
            <div *ngIf="(error | async) && hasError" class="alert alert-danger" role="alert" @fadeOut>{{ (error | async)
              |
              translate }}</div>
            <div *ngIf="(message | async) && hasMessage" class="alert alert-info" role="alert" @fadeOut>{{ (message |
              async) |
              translate }}</div>
          </form>
          <div>
            <div>
              <a routerLink="/register">
                {{ '¿Usuario nuevo? ' }}
                <span>{{ 'Haga clic aquí para registrarse.'}}</span>
              </a>
              <a routerLink="/home">
                <span>Continuar sin cuenta</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</main>