<div class="modal-backdrop" *ngIf="isOpen" (click)="closeModal()"></div>
<div class="modal-container" *ngIf="isOpen">
  <div class="modal-header-items">
    <button class="close-btn" (click)="closeModal()">
      <img class="icon" src="../../assets/images/circle-x.svg" />
    </button>
  </div>
  <div class="modal-body">
    <h2>{{documentTitle && documentTitle}}</h2>
    <p class="description">Selecciona tus redes sociales favoritas y comparte este documento con tus contactos o amigos.
      Si no tienes
      ninguna de estas redes, copia el enlace e inclúyelo en las redes que utilices.</p>
    <div class="social-icons">
      <button class="btn-icons" style="background-color: #25d366;"
        (click)="openUrl('https://api.whatsapp.com/send?text=' + documentUrl)">
        <img loading="lazy" class="icons" src="../../assets/images/whatsapp.png" alt="WhatsApp" />
      </button>
      <button class="btn-icons" style="background-color: #1877f2;"
        (click)="openUrl('https://www.facebook.com/sharer/sharer.php?u=' + documentUrl)">
        <img loading="lazy" class="icons" src="../../assets/images/facebook.png" alt="Facebook" />
      </button>
      <button class="btn-icons" style="background-color: #000;"
        (click)="openUrl('https://twitter.com/intent/tweet?url=' + documentUrl)">
        <img loading="lazy" class="icons" src="../../assets/images/x.png" alt="Twitter" />
      </button>
      <button class="btn-icons" style="background-color: #24A1DE;"
        (click)="openUrl('https://t.me/share/url?url=' + documentUrl)">
        <img loading="lazy" class="icons" src="../../assets/images/telegrama.png" alt="Telegram" />
      </button>
      <button *ngIf="isWebShareSupported()" (click)="share()" class="btn-icons" style="background-color: #e5e5e5;">
        <img loading="lazy" class="icons" src="../../assets/images/more.png" alt="Más" />
      </button>
      <button class="copy-btn" (click)="copyTextToClipboard()" [class.active]="stateButton">
        <img loading="lazy" class="icons"
          [src]="!stateButton ? '../../assets/images/copy-clip.svg': '../../assets/images/check.png'" />
        <span class="copy-btn-text" [class.active]="stateButton">
          {{!stateButton ? 'Copiar enlace': '¡Enlace copiado!'}}
        </span>
      </button>
    </div>
  </div>
</div>