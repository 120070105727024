<div class="container-section">
    <div *ngIf="isLoading" class="loader-container">
        <ds-small-loader [isLoading]="true"></ds-small-loader>
    </div>
    <div *ngIf="!isLoading">
        <div *ngIf="dataSection?.image" class="elementor-background-overlay"
            style="background-image: url('{{dataSection.image}}');">
        </div>
        <h2><strong>{{dataSection?.title}}</strong></h2>
        <ds-carousel [slides]="dataSection?.items" [navigation]="true" [loop]="true" [pagination]="true"
            [scrollbar]="true" [delay]="10000" [disableOnInteraction]="false" [breakpoints]="breakpoints" />
    </div>
</div>