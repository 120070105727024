/* eslint-disable no-unsafe-optional-chaining */
import { Injectable } from '@angular/core';
import axios, { AxiosResponse } from 'axios';
import { catchError, from, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private apiUrl = `${environment?.rest?.strapiUrl}/api/seccion-encabezados?populate[Encabezado][populate][Imagen_izquierda]=*&populate[Encabezado][populate][Imagen_derecha]=*&populate[Encabezado][populate][Logo_encabezado_en_moviles]=*&populate[Encabezado][populate][Redes][populate]=Imagen&populate[Encabezado][populate][Menu]=*`;

  getData(): Observable<any> {
    return from(this.fetchHeaderData()).pipe(
      catchError((error) => {
        console.error('Error fetching data from strapi CMS:');
        return of(this.loadDataFallback());
      })
    );
  }

  private async fetchHeaderData(): Promise<any> {
    try {
      const response: AxiosResponse = await axios.get(this.apiUrl);
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { Encabezado, Texto_de_busqueda, Mostrar_encabezado } =
        response?.data?.data[0]?.attributes;
      const socials = Encabezado?.Redes;
      const menu = Encabezado?.Menu;
      return {
        show: Mostrar_encabezado,
        contactText: Encabezado?.Contacto,
        placeholder: Texto_de_busqueda,
        href: Encabezado?.Enlace,
        righImg: Encabezado?.Imagen_izquierda?.data?.attributes?.url,
        leftImg: Encabezado?.Imagen_derecha?.data?.attributes?.url,
        movileImg:
          Encabezado?.Logo_encabezado_en_moviles?.data?.attributes?.url,
        icons: socials?.map((item) => ({
          href: item?.Enlace,
          image: item?.Imagen?.data?.attributes?.formats?.thumbnail?.url,
        })),
        menuItems: this.getDataMenu(menu),
      };
    } catch (error) {
      console.error('Error in fetchData:', error);
      throw error;
    }
  }
  private getDataMenu(data) {
    if (data) {
      return data
        ?.sort((a, b) => a.attributes?.id - b.attributes?.id)
        ?.map((element) => ({
          id: element?.id,
          label: element?.Titulo,
          href: element?.Enlace,
          targetBlank: element?.Abrir_en_nueva_pagina,
        }));
    }
  }

  // Retuen data fallback
  private loadDataFallback() {
    return {
      show: true,
      contactText: 'Contacto',
      placeholder: 'Ingrese su búsqueda',
      href: '',
      righImg: '../../assets/images/logo-poder-judicial.svg',
      leftImg: '../../assets/images/logo-RD.svg',
      movileImg: '../../assets/images/Escudo-RD.svg',
      icons: [
        {
          href: 'https://www.facebook.com/PoderJudicialRD/',
          image: '../../assets/images/facebook.png',
        },
        {
          href: 'https://x.com/poderjudicialrd',
          image: '../../assets/images/x.png',
        },
        {
          href: 'https://www.instagram.com/PoderJudicialRD/',
          image: '../../assets/images/instagram.png',
        },
        {
          href: 'https://www.youtube.com/user/poderjudicialrd',
          image: '../../assets/images/youTube.png',
        },
        {
          href: 'https://www.flickr.com/photos/148182651@N08/',
          image: '../../assets/images/flickr.png',
        },
      ],
      menuItems: [
        {
          label: 'Inicio',
          href: '/home',
          targetBlank: false,
        },
        {
          label: 'Servicios',
          href: 'servicios',
          targetBlank: false,
        },
        {
          label: 'Anaqueles',
          href: 'anaqueles',
          targetBlank: false,
        },
        {
          label: 'Transparencia',
          href: 'https://transparencia.poderjudicial.gob.do/transparencia/',
          targetBlank: true,
        },
        {
          label: 'Diccionario Jurídico',
          href: '/dictionary',
          targetBlank: false,
        },
        {
          label: 'Novedades Jurídicas',
          href: 'novedades',
          targetBlank: false,
        },
      ],
    };
  }
}
