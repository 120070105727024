<div class="pdf-container">
  <div id="pdf-style-reader">
    <div class="rpv-core__viewer rpv-core__viewer--light" style="height: 100%; width: 100%;">
      <div class="rpv-default-layout__container">
        <div class="rpv-default-layout__toolbar">
          <header>
            <button class="menu-button" (click)="openMenu()">
              <i class="fa-solid fa-bars"></i>
            </button>
            <div class="rpv-toolbar__left">
              <div class="rpv-core__display--hidden rpv-core__display--block-small increment_button">
                <div class="rpv-toolbar__item">
                  <div>
                    <button (click)="handleincrementPage(1)"
                      class="rpv-core__minimal-button rpv-core__minimal-button--disabled" type="button">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"
                        class="lucide lucide-arrow-up-circle">
                        <circle cx="12" cy="12" r="10" />
                        <path d="m16 12-4-4-4 4" />
                        <path d="M12 16V8" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="rpv-toolbar__item page-indicator-container">
                <span class="rpv-toolbar__label">{{ page }} / {{ numPages }}</span>
              </div>
              <div class="rpv-core__display--hidden rpv-core__display--block-small increment_button">
                <div class="rpv-toolbar__item">
                  <div aria-describedby="rpv-core__tooltip-body-page-navigation-next">
                    <button (click)="handleincrementPage(-1)" class="rpv-core__minimal-button" type="button">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"
                        class="lucide lucide-arrow-down-circle">
                        <circle cx="12" cy="12" r="10" />
                        <path d="M12 8v8" />
                        <path d="m8 12 4 4 4-4" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="zoom_container">
              <div class="rpv-toolbar__item">
                <div aria-describedby="rpv-core__tooltip-body-zoom-out">
                  <button (click)="handleClickZoom(-0.1)" class="rpv-core__minimal-button zoom_button" type="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="lucide lucide-minus">
                      <path d="M5 12h14" />
                    </svg>
                  </button>
                </div>
              </div>
              <div class="rpv-core__display--hidden rpv-core__display--block-small">
                <div class="rpv-toolbar__item indicator-zoom-container">
                  <div>
                    <span data-testid="zoom__popover-target-scale"
                      class="indicator-zoom rpv-zoom__popover-target-scale--ltr">{{
                      zoomValue | number:'1.0' }}%</span><span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="rpv-toolbar__item">
                <div aria-describedby="rpv-core__tooltip-body-zoom-in">
                  <button (click)="handleClickZoom(0.1)" aria-label="Zoom in" aria-keyshortcuts="Ctrl+="
                    class="rpv-core__minimal-button zoom_button" type="button" data-testid="zoom__in-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="lucide lucide-plus">
                      <path d="M5 12h14" />
                      <path d="M12 5v14" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <button class="menu_button" (click)="handleShowMenu()">
              <img class="icon-open"
                [src]="showMenu ? '../../assets/images/close-icon.svg' : '../../assets/images/icon-elements-pdf.svg'" />
            </button>
            <div class="rpv-toolbar__right button_container">
              <div class="rpv-core__display--hidden rpv-core__display--block-medium">
                <div class="rpv-toolbar__item">
                  <div class="rotate_button">
                    <button (click)="handleRotate(-1)" class="rpv-core__minimal-button" type="button"
                      data-testid="theme__switch-button">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"
                        class="lucide lucide-rotate-ccw">
                        <path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
                        <path d="M3 3v5h5" />
                      </svg>
                    </button>
                  </div>
                  <span class="indicator-rotate">{{rotation}}</span>
                  <div>
                    <button (click)="handleRotate(0)" class="rpv-core__minimal-button" type="button"
                      data-testid="theme__switch-button">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"
                        class="lucide lucide-rotate-cw">
                        <path d="M21 12a9 9 0 1 1-9-9c2.52 0 4.93 1 6.74 2.74L21 8" />
                        <path d="M21 3v5h-5" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="rpv-core__display--hidden rpv-core__display--block-medium">
                <div class="rpv-toolbar__item">
                  <div aria-describedby="rpv-core__tooltip-body-get-file">
                    <button (click)="download()" class="rpv-core__minimal-button" type="button"
                      data-testid="get-file__download-button">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"
                        class="lucide lucide-download">
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                        <polyline points="7 10 12 15 17 10" />
                        <line x1="12" x2="12" y1="15" y2="3" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="rpv-core__display--hidden rpv-core__display--block-medium">
                <div class="rpv-toolbar__item">
                  <button (click)="handleModal()" class="rpv-core__minimal-button" type="button">
                    <svg aria-hidden="true" class="rpv-core__icon" stroke-width="1.4" focusable="false" height="24"
                      viewBox="0 0 24 24" width="24">
                      <path d="M10.5,0.5c5.523,0,10,4.477,10,10s-4.477,10-10,10s-10-4.477-10-10S4.977,0.5,10.5,0.5z
                                      M23.5,23.5
                                      l-5.929-5.929"></path>
                    </svg>
                  </button>
                  <div *ngIf="openModal" class="sc-1su61oq-0 fogRei" style="transform: translateY(0px);">
                    <div class="z3x4eh-0 gJFPmw">
                      <div>
                        <div class="sc-2xfn8l-0 bWaBkl sc-2wite4-0 itDlvX"><svg xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <path
                              d="M15.433 14.733 20 19.3l-.7.7-4.567-4.567a6.5 6.5 0 1 1 .7-.7M10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11">
                            </path>
                          </svg></div>
                      </div><input placeholder="Buscar" #queryInp type="text"
                        (keydown)="searchQueryChanged(queryInp.value)" autocomplete="off"
                        (input)="searchQueryChanged($any($event.target).value)" class="z3x4eh-3 krZtqY" value="">
                      <div class="z3x4eh-1 kkuuxE">{{results}}</div>
                      <hr class="z3x4eh-2 ilxlTT">
                      <div class="z3x4eh-5 grFMez">
                        <button type="button" (click)="handleincrementPage(-1)" class="z3x4eh-4 gBKspv">
                          <div class="sc-2xfn8l-0 bWaBkl l0r692-0 QKJVg"><svg xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16">
                              <path d="M13.333 12 8 6.5 2.667 12l-1.334-1.333L8 4l6.667 6.667z"></path>
                            </svg></div>
                        </button>
                        <button type="button" (click)="handleincrementPage(1)" class="z3x4eh-4 gBKspv">
                          <div class="sc-2xfn8l-0 bWaBkl l0r692-0 QKJVg"><svg xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16">
                              <path d="m13.333 4 1.334 1.333L8 12 1.333 5.333 2.667 4 8 9.5z"></path>
                            </svg></div>
                        </button>
                        <button (click)="handleModal()" type="button" class="z3x4eh-4 gBKspv">
                          <div class="sc-2xfn8l-0 bWaBkl l0r692-0 QKJVg"><svg xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16">
                              <path
                                d="M8.923 8 14 13.077l-.923.923L8 8.923 2.923 14 2 13.077 7.077 8 2 2.923 2.923 2 8 7.077 13.077 2l.923.923z">
                              </path>
                            </svg></div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div class="rpv-core__inner-pages rpv-core__inner-pages--vertical">
          <section *ngIf="showMenu">
            <div class="sub-container-top-sidebar">
              <div class="increment-block">
                <button (click)="handleincrementPage(1)"
                  class="rpv-core__minimal-button rpv-core__minimal-button--disabled" type="button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-arrow-up-circle">
                    <circle cx="12" cy="12" r="10" />
                    <path d="m16 12-4-4-4 4" />
                    <path d="M12 16V8" />
                  </svg>
                </button>
                <div style="display:flex">
                  <span class="rpv-toolbar__label">{{ page }} / {{ numPages }}</span>
                </div>
                <button (click)="handleincrementPage(-1)" class="rpv-core__minimal-button" type="button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-arrow-down-circle">
                    <circle cx="12" cy="12" r="10" />
                    <path d="M12 8v8" />
                    <path d="m8 12 4 4 4-4" />
                  </svg>
                </button>
              </div>
              <div style="display: flex; align-items: center; gap: 12px;">
                <button (click)="handleClickZoom(-0.1)" class="rpv-core__minimal-button zoom_button" type="button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-minus">
                    <path d="M5 12h14" />
                  </svg>
                </button>
                <span data-testid="zoom__popover-target-scale"
                  class="indicator-zoom rpv-zoom__popover-target-scale--ltr" style="margin: 0 !important;">{{
                  zoomValue | number:'1.0' }}%
                </span>
                <button (click)="handleClickZoom(0.1)" aria-label="Zoom in" aria-keyshortcuts="Ctrl+="
                  class="rpv-core__minimal-button zoom_button" type="button" data-testid="zoom__in-button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-plus">
                    <path d="M5 12h14" />
                    <path d="M12 5v14" />
                  </svg>
                </button>
              </div>
              <div>
                <div>
                  <button (click)="handleRotate(-1)" class="rpv-core__minimal-button" type="button"
                    data-testid="theme__switch-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"
                      class="lucide lucide-rotate-ccw">
                      <path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
                      <path d="M3 3v5h5" />
                    </svg>
                  </button>
                  <span class="indicator-rotate">{{rotation}}</span>
                  <button (click)="handleRotate(0)" class="rpv-core__minimal-button" type="button"
                    data-testid="theme__switch-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"
                      class="lucide lucide-rotate-cw">
                      <path d="M21 12a9 9 0 1 1-9-9c2.52 0 4.93 1 6.74 2.74L21 8" />
                      <path d="M21 3v5h-5" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="search-sidebar">
              <input placeholder="Buscar" #queryInp type="text" (keydown)="searchQueryChanged(queryInp.value)"
                autocomplete="off" (input)="searchQueryChanged($any($event.target).value)" class="z3x4eh-3 krZtqY"
                value="">
              <hr class="z3x4eh-2 ilxlTT">
              <div class="z3x4eh-5 grFMez">
                <div class="z3x4eh-1 kkuuxE">{{results}}</div>
                <button type="button" (click)="handleincrementPage(-1)" class="z3x4eh-4 gBKspv">
                  <div class="sc-2xfn8l-0 bWaBkl l0r692-0 QKJVg"><svg xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16">
                      <path d="M13.333 12 8 6.5 2.667 12l-1.334-1.333L8 4l6.667 6.667z"></path>
                    </svg></div>
                </button>
                <button type="button" (click)="handleincrementPage(1)" class="z3x4eh-4 gBKspv">
                  <div class="sc-2xfn8l-0 bWaBkl l0r692-0 QKJVg"><svg xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16">
                      <path d="m13.333 4 1.334 1.333L8 12 1.333 5.333 2.667 4 8 9.5z"></path>
                    </svg></div>
                </button>
                <button (click)="handleClear()" type="button" class="z3x4eh-4 gBKspv">
                  <div class="sc-2xfn8l-0 bWaBkl l0r692-0 QKJVg"><svg xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16">
                      <path
                        d="M8.923 8 14 13.077l-.923.923L8 8.923 2.923 14 2 13.077 7.077 8 2 2.923 2.923 2 8 7.077 13.077 2l.923.923z">
                      </path>
                    </svg></div>
                </button>
              </div>
            </div>
          </section>
          <div id="outerContainer">
            <div style="width: 100%; height: 100vh; position: relative; background: rgb(232, 232, 232);">
              <div
                style="width: 100%; height: 100%; position: absolute; display: flex; justify-content: center; align-items: center;">
                <ds-loading *ngIf="isLoading"></ds-loading>
              </div>
              <pdf-viewer [src]="url" [(page)]="page" [rotation]="rotation" [original-size]="true" [show-all]="true"
                [fit-to-page]="true" [page]="url" [show-all]="true" [zoom]="zoomValue" [zoom-scale]="'page-width'"
                [stick-to-page]="false" [render-text]="true" [external-link-target]="'blank'" [autoresize]="true"
                [show-borders]="true" (after-load-complete)="IsLoading($event)"
                style="width: 100%; height: 100%; max-width: 100vw; max-height: 100vh;"></pdf-viewer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>