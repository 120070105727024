import { Component, Input, OnChanges } from '@angular/core';
@Component({
  selector: 'ds-home-news',
  styleUrls: ['./home-news.component.scss'],
  templateUrl: './home-news.component.html',
})

/**
 * Component to render the news section on the home page
 */
export class HomeNewsComponent implements OnChanges {
  image = '';
  @Input() data;
  @Input() isLoading;

  ngOnChanges(): void {
    this.image = this.data;
  }
}
