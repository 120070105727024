import { Injectable, Inject } from '@angular/core';
import { FileService } from '../shared/file.service';
import { environment } from 'src/environments/environment';
import { getBaseUrl } from 'src/app/shared/utils/route.utils';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  private pdfLink$ = new BehaviorSubject<string>('');
  private identifier = '';
  private initialEndLink = `${environment.rest.baseUrl}/api/core/`;
  private endLink = this.initialEndLink;
  private returnValue = false;

  constructor(
    public fileService: FileService,
    @Inject(DOCUMENT) protected _doc: any
  ) {}

  setPdfLink(link: string): void {
    this.returnValue = true;
    this.pdfLink$.next(link);
    this.identifier = this.extractIdentifier();

    this.fileService.retrieveFileDownloadLink(link).subscribe((data) => {
      const afterDownload = this.extractAfterDownload(data);
      this.endLink = this.buildEndLink(afterDownload);
      this.pdfLink$.next(this.endLink);
    });
  }

  private extractIdentifier(): string {
    const currentBaseUrl = getBaseUrl();
    return this.pdfLink$
      .getValue()
      .replace(`${currentBaseUrl}/`, '')
      .replace('download', '');
  }

  private extractAfterDownload(data: string): string {
    return data.split('download?')[1];
  }

  private buildEndLink(afterDownload: string): string {
    return `${this.initialEndLink}${this.identifier}content?${afterDownload}`;
  }

  clearPdfLink(): void {
    this.pdfLink$.next('');
  }

  getLink(): Observable<string> {
    return this.pdfLink$.asObservable();
  }
}
