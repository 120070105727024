<section>
  <div *ngIf="isLoading"  class="loader-container">
    <ds-small-loader [isLoading]="true"></ds-small-loader>
  </div>
  <div *ngIf="!isLoading" class="elementor-section" style="background-image: url('{{image}}');">
    <div class="elementor-background-overlay"></div>
    <div class="elementor-container elementor-column-gap-default">
      <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-61884db">
        <div class="elementor-widget-wrap"></div>
      </div>
    </div>
  </div>
</section>