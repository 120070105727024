import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  SortOptions,
  SortDirection,
} from '../../core/cache/models/sort-options.model';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { Collection } from 'src/app/core/shared/collection.model';
import { COMMUNITY_PAGE_LINKS_TO_FOLLOW } from 'src/app/community-page/community-page.resolver';
import { getFirstCompletedRemoteData } from 'src/app/core/shared/operators';
import { Observable } from 'rxjs';
import { RemoteData } from 'src/app/core/data/remote-data';
import { PaginatedList } from 'src/app/core/data/paginated-list.model';
import { CommunityDataService } from '../../core/data/community-data.service';
import { DSONameService } from 'src/app/core/breadcrumbs/dso-name.service';
import { FindListOptions } from '../../core/data/find-list-options.model';
import { CommunityListService } from 'src/app/community-list-page/community-list-service';
import { CommunityListDatasource } from 'src/app/community-list-page/community-list-datasource';
import { ScrollService } from 'src/app/shared/utils/scroll.service';

/**
 * this component renders the view-communities list cards
 */
@Component({
  selector: 'ds-view-communities',
  styleUrls: ['./view-communities.component.scss'],
  templateUrl: './view-communities.component.html',
  animations: [fadeInOut],
})
export class ViewCommunitiesComponent implements OnInit {
  @Input() data;
  @Input() isLoading;
  dataSection: any = {};
  dataSource: CommunityListDatasource;
  paginationConfig: FindListOptions;
  dataCommunities: any[] = [];
  displayedCommunities: any[] = [];
  imagesLoading: { [key: string]: boolean } = {};
  loading = false;
  communitiesPerPage = 5;
  currentPage = 1;

  constructor(
    private cdr: ChangeDetectorRef,
    public dsoNameService: DSONameService,
    private scrollService: ScrollService,
    private communityService: CommunityDataService,
    protected communityListService: CommunityListService
  ) {
    this.paginationConfig = new FindListOptions();
    this.paginationConfig.elementsPerPage = 4;
    this.paginationConfig.currentPage = 1;
    this.paginationConfig.sort = new SortOptions(
      'dc.date.accessioned',
      SortDirection.DESC
    );
  }

  /* The `ngOnInit()` method in the code initializes the component by setting up the data source for
  the community availables, loading communities based on pagination configuration*/
  ngOnInit() {
    this.dataSource = new CommunityListDatasource(this.communityListService);
    this.dataSource.loadCommunities(this.paginationConfig, []);
    this.dataSource.getCommunityList$().subscribe((data) => {
      if (data?.length !== 0) {
        this.dataCommunities = data;
        this.updateDisplayedCommunities();
        this.cdr.detectChanges();
        this.dataCommunities.forEach((community) => {
          this.renderInfoCommunity(community);
        });
      } else {
        this.cdr.detectChanges();
        this.loading = false;
      }
    });
    this.dataSection = this.data;
  }

  toggleCommunities() {
    if (this.displayedCommunities.length < this.dataCommunities.length) {
      this.currentPage++;
    } else {
      this.currentPage = Math.max(1, this.currentPage - 1);
      this.scrollService.scrollTo('anaqueles');
    }
    this.updateDisplayedCommunities();
  }

  // Update all data primary show
  updateDisplayedCommunities() {
    this.displayedCommunities = this.dataCommunities.slice(
      0,
      this.currentPage * this.communitiesPerPage
    );
  }

  // Load data all community
  renderInfoCommunity(community) {
    const communityRD$ = this.communityService
      .findById(community.id, false, false, ...COMMUNITY_PAGE_LINKS_TO_FOLLOW)
      .pipe(getFirstCompletedRemoteData());
    communityRD$.subscribe((communityRD) => {
      if (communityRD && communityRD.payload) {
        this.loading = false;
        const collections$ = communityRD.payload.collections as Observable<
          RemoteData<PaginatedList<Collection>>
        >;
        // Get number of collections from the listed communities
        collections$.subscribe((collectionsRD) => {
          if (collectionsRD.hasSucceeded && collectionsRD.payload) {
            const collections = collectionsRD.payload.page;
            const collectionCount = collections.length;
            const index = this.dataCommunities.findIndex(
              (c) => c.id === community.id
            );
            if (index !== -1) {
              this.dataCommunities[index].collectionCount = collectionCount;
            }
          }
        });

        communityRD.payload.logo.subscribe((logoRD) => {
          const id = logoRD.payload?.id;
          const icon = logoRD?.payload?._links?.content?.href;
          const index = this.dataCommunities.findIndex(
            (c) => c.id === community.id
          );
          if (index !== -1) {
            this.dataCommunities[index].logo = {
              id: id,
              src: icon,
            };
            this.imagesLoading[community.id] = true;
          }
          this.cdr.detectChanges();
        });
      }
    });
  }

  errorHandler(communityId: string) {
    this.imagesLoading[communityId] = false;
  }
  successHandler(communityId: string) {
    this.imagesLoading[communityId] = false;
    this.cdr.detectChanges();
  }

  getToggleButtonText(): string {
    return this.displayedCommunities.length < this.dataCommunities.length
      ? this.dataSection?.showMore
      : 'Cargar menos';
  }
}
