<div class="navbar-container" [class.showMenu]="showMenu">
  <button class="button-menu" [class.hide]="!menuMode">
    <i class="fa-solid fa-xmark" [class.hide]="!showMenu" (click)="handleShowMenu()"></i>
  </button>
  <div [class.menu]="menuMode && !showMenu">
    <header *ngIf="showHeader">
      <picture *ngIf="imageSrc !== undefined && !isLoadingImage" class="mb-2">
        <img [src]="imageSrc" (load)="onImageLoad()" (error)="onImageError()" [ngClass]="{'d-none': isLoadingImage}" />
      </picture>
      <div *ngIf="isLoadingImage" class="container-loader">
        <ds-small-loader [isLoading]="true"></ds-small-loader>
      </div>
      <div class="container-not-img">
        <div *ngIf="imageSrc === undefined && !isLoadingImage" class="thumbnail-content outer">
          <div class="inner">
            <div class="thumbnail-placeholder centered lead">
              No hay miniatura disponible.
            </div>
          </div>
        </div>
      </div>
      <section class="button-container">
        <div>
          <button class="download-button" (click)="downloadBook()">
            <img class="download-icon" src="../../../../../assets/images/download.svg" />
            <span>
              {{ 'item.navbar.download' | translate}}
            </span>
          </button>
        </div>
      </section>
    </header>
    <section *ngIf="object?.metadata">
      <h4>{{ 'item.navbar.details' | translate}}</h4>
      <article>
        <h5>{{ 'item.navbar.title' | translate}}</h5>
        <span>{{object?.metadata["dc.title"] ? object?.metadata["dc.title"][0]?.value: 'No hay título disponible.'}}</span>
      </article>
      <article>
        <h5>{{ 'item.navbar.date' | translate}}</h5>
        <span>{{object?.metadata["dc.date.issued"] ? object?.metadata["dc.date.issued"][0]?.value :'No hay fecha disponible.'}}</span>
      </article>
      <article>
        <h5>{{ 'item.navbar.author' | translate}}</h5>
        <span>{{object?.metadata["dc.contributor.author"] ? object?.metadata["dc.contributor.author"][0]?.value: 'No hay autores disponibles.'}}</span>
      </article>
      <article>
        <h5>{{ 'item.navbar.publisher' | translate}}</h5>
        <span>{{object?.metadata["dc.publisher"] ? object?.metadata["dc.publisher"][0]?.value: 'No hay datos disponibles.'}}</span>
      </article>
    </section>
  </div>
</div>