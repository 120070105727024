import { Component, Input } from '@angular/core';
/**
 * This component represents the loader small
 */
@Component({
  selector: 'ds-small-loader',
  styleUrls: ['./small-loader.component.scss'],
  templateUrl: './small-loader.component.html',
})
export class SmallLoaderComponent {
  @Input() isLoading = false;
}
