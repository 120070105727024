import { Meta, Title } from '@angular/platform-browser';

export function updateMetaTags(meta: Meta, title: Title, data): void {
  const site_name = 'Datahub Judicial';
  title.setTitle(`${site_name} :: ${data.title}`);
  meta.updateTag({
    name: 'description',
    content: data?.description,
  });
  meta.updateTag({
    property: 'og:title',
    content: data?.title,
  });
  meta.updateTag({
    property: 'og:description',
    content: data?.description,
  });
  meta.updateTag({
    property: 'og:url',
    content: data?.url,
  });
  meta.updateTag({
    name: 'twitter:url',
    content: data?.url,
  });
  meta.updateTag({ property: 'og:site_name', content: site_name });
  meta.updateTag({ property: 'og:image', content: data?.image });
  meta.updateTag({ property: 'og:type', content: 'website' });
  meta.updateTag({
    name: 'twitter:title',
    content: data?.title,
  });
  meta.updateTag({
    name: 'twitter:card',
    content: 'summary_large_image',
  });
  meta.updateTag({ name: 'twitter:site', content: site_name });
  meta.updateTag({ name: 'twitter:image', content: data?.image });
  meta.updateTag({
    name: 'twitter:description',
    content: data?.description,
  });
}
