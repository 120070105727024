<ds-themed-results-back-button *ngIf="showBackButton | async" [back]="back"></ds-themed-results-back-button>
<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer id="iiif-viewer" [object]="object" [searchable]="iiifSearchEnabled" [query]="iiifQuery$ | async">
    </ds-mirador-viewer>
  </div>
</div>
<div class="container-details">
  <ds-view-item [object]="object"></ds-view-item>
</div>
<div *ngIf="itemId" class="relacionate-items">
  <ds-related-second-items [itemId]="itemId" [title]="'Contenido relacionado'"
    [route]="'items'"></ds-related-second-items>
</div>