<div class="section-form">
  <div class="section-form-boxed">
    <form class="form" [formGroup]="searchForm" (ngSubmit)="onSubmit(searchForm.value)" autocomplete="on">
      <div class="bt-position">
        <span type="button" class="bdt-search-icon" (click)="onSubmit(searchForm.value)">
          <img class="svg" src="assets/images/icon-search.svg">
        </span>
        <input name="query" #searchInput formControlName="query" [placeholder]="placeHolder" class="bdt-search-input"
          type="text" title="Search">
      </div>
    </form>
  </div>
</div>