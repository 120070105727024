<div class="slide-container">
  <h1 class="title">{{title}}</h1>
  <div *ngIf="loadingData; else itemsList"
    style="display: flex; justify-content: center; align-items: center; width: 100%; height: 110px;">
    <ds-small-loader [isLoading]="loadingData"></ds-small-loader>
  </div>
  <ng-template #itemsList>
    <div *ngIf="dataRelatedItems?.length > 0; else noItems" class="carousel">
      <swiper class="related-content-carousel no-transform" [ngClass]="{'no-transform': dataRelatedItems?.length <= 2}"
        [navigation]="true" [autoplay]="{
        delay: delay,
        disableOnInteraction: false
    }" [scrollbar]="{ draggable: true }" [loop]="false" [breakpoints]="breakpoints" [pagination]="{ clickable: true }">
        <ng-template swiperSlide *ngFor="let data of dataRelatedItems;">
          <div class="item-container" (click)="handleRedirect(data.redirect)">
            <div *ngIf="data.image.image !== null" class="image-container">
              <img [src]="data.image.image" class="image" [ngClass]="{'d-none': imagesLoading[data.id]}"
                (error)="errorHandler(data.id)" (load)="successHandler(data.id)" />
              <ds-small-loader *ngIf="imagesLoading[data.id]" [isLoading]="true"></ds-small-loader>
            </div>
            <div *ngIf="data.image.image === null" class="image-container">
              <div class="thumbnail-content outer">
                <div class="inner">
                  <div class="thumbnail-placeholder centered lead">
                    No hay miniatura disponible.
                  </div>
                </div>
              </div>
            </div>
            <p class="date">{{data?.date}}</p>
            <div style="display: flex; flex-direction: column;">
              <div class="data-container">
                <h3>{{ data?.title }}</h3>
              </div>
              <div class="sub-content">
                <p class="sub-data">{{ data?.editorial }}</p>
              </div>
            </div>
          </div>
        </ng-template>
      </swiper>
    </div>
  </ng-template>
  <ng-template #noItems>
    <div class="alert-container">
      <div class="not-relacionate-items">No se encontraron contenidos
        relacionados.</div>
      <!--<p class="not-relacionate-items"></p>-->
    </div>
  </ng-template>
</div>