import { NavbarData } from './navbar';
import { AppState } from '../app.reducer';
import { select, Store } from '@ngrx/store';
import { hasValue } from '../shared/empty.util';
import { ActivatedRoute } from '@angular/router';
import { MenuID } from '../shared/menu/menu-id.model';
import { Observable } from 'rxjs/internal/Observable';
import { LogOutAction } from '../core/auth/auth.actions';
import { isAuthenticated } from '../core/auth/selectors';
import { MenuService } from '../shared/menu/menu.service';
import { slideMobileNav } from '../shared/animations/slide';
import { MenuComponent } from '../shared/menu/menu.component';
import { BrowseService } from '../core/browse/browse.service';
import { ScrollService } from '../shared/utils/scroll.service';
import { HeaderService } from '../core/services/header.service';
import { HostWindowService } from '../shared/host-window.service';
import { ThemeService } from '../shared/theme-support/theme.service';
import { Component, Injector, Input, OnChanges } from '@angular/core';
import { AuthorizationDataService } from '../core/data/feature-authorization/authorization-data.service';

/**
 * Component representing the public navbar
 */
@Component({
  selector: 'ds-navbar',
  styleUrls: ['./navbar.component.scss'],
  templateUrl: './navbar.component.html',
  animations: [slideMobileNav],
})
export class NavbarComponent extends MenuComponent implements OnChanges {
  @Input() data;
  @Input() infoUser;
  user;
  navbarLinks = [];
  navbarData: NavbarData = {
    accessText: 'Iniciar sesión',
    login: '/login',
  };

  menuItems: any[] = [];

  isShowMenu = false;
  /**
   * The menu ID of the Navbar is PUBLIC
   * @type {MenuID.PUBLIC}
   */
  menuID = MenuID.PUBLIC;

  /**
   * Whether user is authenticated.
   * @type {Observable<string>}
   */
  public isAuthenticated$: Observable<boolean>;

  public isXsOrSm$: Observable<boolean>;

  constructor(
    protected menuService: MenuService,
    protected injector: Injector,
    public windowService: HostWindowService,
    public browseService: BrowseService,
    public authorizationService: AuthorizationDataService,
    public route: ActivatedRoute,
    public scrollService: ScrollService,
    protected themeService: ThemeService,
    private store: Store<AppState>,
    public topMenuService: HeaderService
  ) {
    super(menuService, injector, authorizationService, route, themeService);
  }

  ngOnChanges(): void {
    super.ngOnInit();
    this.isXsOrSm$ = this.windowService.isMd();

    if (hasValue(this.data)) {
      const { menuItems } = this.data;
      this.navbarLinks = menuItems;
    }
    if (hasValue(this.infoUser)) {
      this.user = this.infoUser;
    }

    this.isAuthenticated$ = this.store.pipe(select(isAuthenticated));
  }
  toggleNavbar() {
    if (this.isXsOrSm$) {
      this.menuService.toggleNavbar();
    }
  }

  getStateNavbar() {
    return this.menuService.getStateNavbar();
  }

  handleScroll(id: string) {
    this.scrollService.scrollToSection(id);
    this.toggleNavbar();
  }

  public logOut() {
    this.store.dispatch(new LogOutAction());
  }
}
