import { hasValue } from '../empty.util';

export function handleDownload(url: string): string {
    if (hasValue(url)) {
        try {
            let element = document.createElement('a');
            element.href = url;
            element.download = '';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            return '¡Tu documento se está descargando con éxito!';
        } catch (error) {
            console.error('Error al intentar descargar el archivo:', error);
            return '¡Ocurrió un error al descargar el documento!';
        }
    } else {
        return 'URL no válida. No se puede iniciar la descarga.';
    }
}
