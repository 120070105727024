import { filter, map, startWith } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { combineLatest as combineLatestObservable, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { MetadataService } from '../core/metadata/metadata.service';
import { HostWindowState } from '../shared/search/host-window.reducer';
import {
  NativeWindowRef,
  NativeWindowService,
} from '../core/services/window.service';
import { AuthService } from '../core/auth/auth.service';
import { CSSVariableService } from '../shared/sass-helper/css-variable.service';
import { MenuService } from '../shared/menu/menu.service';
import { HostWindowService } from '../shared/host-window.service';
import { ThemeConfig } from '../../config/theme.config';
import { Angulartics2DSpace } from '../statistics/angulartics/dspace-provider';
import { environment } from '../../environments/environment';
import { slideSidebarPadding } from '../shared/animations/slide';
import { MenuID } from '../shared/menu/menu-id.model';
import {
  FORGOT_PASSWORD_PATH,
  getPageInternalServerErrorRoute,
  INTERNAL_SERVER_ERROR,
  LOGIN_PATH,
  REGISTER_PATH,
} from '../app-routing-paths';
import { hasValue, hasValueOperator } from '../shared/empty.util';
import { DSONameService } from '../core/breadcrumbs/dso-name.service';

@Component({
  selector: 'ds-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  animations: [slideSidebarPadding],
})
export class RootComponent implements OnInit {
  @ViewChild('footer') footer: ElementRef;
  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('dir') dir: ElementRef;
  headerData;
  routeSubscription: any;
  infoUser = {};
  sidebarVisible: Observable<boolean>;
  slideSidebarOver: Observable<boolean>;
  collapsedSidebarWidth: Observable<string>;
  totalSidebarWidth: Observable<string>;
  theme: Observable<ThemeConfig> = of({} as any);
  notificationOptions;
  models;

  public isAuthenticated$: Observable<boolean>;

  @Input() data;

  /**
   * Whether or not to show a full screen loader
   */
  @Input() shouldShowFullscreenLoader: boolean;

  /**
   * Whether or not to show a loader across the router outlet
   */
  @Input() shouldShowRouteLoader: boolean;

  constructor(
    @Inject(NativeWindowService) private _window: NativeWindowRef,
    private translate: TranslateService,
    private store: Store<HostWindowState>,
    private metadata: MetadataService,
    private angulartics2DSpace: Angulartics2DSpace,
    private authService: AuthService,
    private router: Router,
    private cssService: CSSVariableService,
    private menuService: MenuService,
    private dsoNameService: DSONameService,
    private windowService: HostWindowService
  ) {
    this.notificationOptions = environment.notifications;
  }

  ngOnInit() {
    if (this.data) {
      this.headerData = this.data;
    }
    this.sidebarVisible = this.menuService.isMenuVisibleWithVisibleSections(
      MenuID.ADMIN
    );

    this.collapsedSidebarWidth = this.cssService
      .getVariable('--ds-collapsed-sidebar-width')
      .pipe(hasValueOperator());
    this.totalSidebarWidth = this.cssService
      .getVariable('--ds-total-sidebar-width')
      .pipe(hasValueOperator());

    const sidebarCollapsed = this.menuService.isMenuCollapsed(MenuID.ADMIN);
    this.slideSidebarOver = combineLatestObservable([
      sidebarCollapsed,
      this.windowService.isXsOrSm(),
    ]).pipe(
      map(([collapsed, mobile]) => collapsed || mobile),
      startWith(true)
    );

    if (this.router.url === getPageInternalServerErrorRoute()) {
      this.shouldShowRouteLoader = false;
    }

    // Current user
    this.getInfoUser();
  }

  // Get info user method
  private getInfoUser() {
    this.authService.getAuthenticatedUserFromStore().subscribe((data) => {
      if (hasValue(data?.metadata)) {
        this.infoUser = {
          email: data?.email ? data.email : null,
          firstName: data?.metadata['eperson.firstname']
            ? data?.metadata['eperson.firstname'][0]?.value
            : null,
          lastName: data?.metadata['eperson.lastname']
            ? data?.metadata['eperson.lastname'][0]?.value
            : null,
          phone: data?.metadata['eperson.phone']
            ? data?.metadata['eperson.phone'][0]?.value
            : null,
        };
      }
    });
  }

  skipToMainContent() {
    const mainContent = document.getElementById('main-content');
    if (mainContent) {
      mainContent.tabIndex = -1;
      mainContent.focus();
    }
  }

  ngAfterViewInit() {
    this.routeSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateLayout();
      });

    // Initial layout update
    this.updateLayout();
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  private updateLayout() {
    const shouldRemove =
      this.router.url.search(LOGIN_PATH) === 1 ||
      this.router.url.search(REGISTER_PATH) === 1 ||
      this.router.url.search(FORGOT_PASSWORD_PATH) === 1 ||
      this.router.url.search(INTERNAL_SERVER_ERROR) === 1;

    if (shouldRemove) {
      this.footer.nativeElement.style.display = 'none';
      this.wrapper.nativeElement.style.display = 'none';
      this.dir.nativeElement.style.display = 'none';
    } else {
      this.footer.nativeElement.style.display = 'block';
      this.wrapper.nativeElement.style.display = 'block';
      this.dir.nativeElement.style.display = 'block';
    }
  }
}
