import { ScrollService } from '../shared/utils/scroll.service';
import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TypeDataFooter } from './footer.model';

@Component({
  selector: 'ds-footer',
  styleUrls: ['footer.component.scss'],
  templateUrl: 'footer.component.html',
})
export class FooterComponent {
  constructor(public scroll: ScrollService, private sanitizer: DomSanitizer) {}

  dataFooter: TypeDataFooter = {
    year: new Date().getFullYear(),
    telephone: '809-533-3191',
    email: 'contacto@poderjudicial.gob.do',
    copyright: 'Todos los Derechos Reservados',
    certification: 'Portal web certificado bajo la NORTIC A2',
    texts: [
      'Poder Judicial de la República Dominicana.',
      'Av. Enrique Jiménez Moya, esquina Juan de Dios Ventura Simó, Santo Domingo.',
      'Código Postal 10101 / Apartado Postal 1485',
    ],
    links: [
      {
        title: 'Términos de Uso',
        // href: '/terminos-de-uso',
      },
      {
        title: 'Políticas de Privacidad',
        // href: '/politicas-de-privacidad',
      },
      {
        title: 'Preguntas Frecuentes',
        // href: '/preguntas-frecuentes',
      },
    ],
    titles: [
      'Poder Judicial de la República Dominicana.',
      'Av. Enrique Jiménez Moya, esquina Juan de Dios Ventura Simó, Santo Domingo.',
      'Código Postal 10101 / Apartado Postal 1485',
    ],
    icons: {
      href: 'https://nortic.ogtic.gob.do/instituciones/PJ',
      imgs: [
        {
          title:
            'Norma para el Desarrollo y Gestión de los Portales Web y la Transparencia de los Organismos del Estado Dominicano.',
          src: this.getSafeUrl(
            '../../assets/images/nortic_A2_2021.svg'
          ),
        },
        {
          title:
            'Norma sobre Publicación de Datos Abiertos del Gobierno Dominicano.',
          src: this.getSafeUrl(
            '../../assets/images/nortic_A3_2014.svg'
          ),
        },
        {
          title:
            'Norma para la Interoperabilidad entre los Organismos del Gobierno Dominicano.',
          src: this.getSafeUrl(
            '../../assets/images/nortic_A4_2014.svg'
          ),
        },
        {
          title:
            'Norma sobre la Prestación y Automatización de los Servicios Públicos del Estado Dominicano.',
          src: this.getSafeUrl(
              '../../assets/images/nortic_A5_2019.svg'
          ),
        },
        {
          title:
            'Norma sobre la Prestación y Automatización de los Servicios Públicos del Estado Dominicano.',
          src: this.getSafeUrl(
              '../../assets/images/nortic_A6_2016.svg'
          ),
        },
        {
          title: 'Norma sobre Accesibilidad Web del Estado Dominicano.',
          src: this.getSafeUrl(
             '../../assets/images/nortic_B2_2018.svg'
          ),
        },
        {
          title:
            'Norma sobre la Prestación y Automatización de los Servicios Públicos del Estado Dominicano.',
          src: this.getSafeUrl(
              '../../assets/images/nortic_E1_2022.svg'
          ),
        },
      ],
    },
  };

  getSafeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
