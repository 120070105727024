import { PdfService } from '../core/data/pdf.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SafeResourceUrl } from '@angular/platform-browser';
import { RouteService } from '../core/services/route.service';
import { ItemDataService } from '../core/data/item-data.service';
import { PageLinksService } from '../core/data/page-links.service';
import { ITEM_PAGE_LINKS_TO_FOLLOW } from '../item-page/item.resolver';
import { ItemNavbarComponent } from '../item-navbar/item-navbar.component';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';

@Component({
  selector: 'ds-pdf-viewer-component',
  styleUrls: ['./pdf-viewer-container.component.scss'],
  templateUrl: './pdf-viewer-container.component.html',
})
export class PdfViewerContainerComponent implements OnInit {
  fileUrl: SafeResourceUrl;
  itemId = null;
  imageSrc: any;
  pdfData$: any;

  @ViewChild(ItemNavbarComponent) ItemNavbar!: ItemNavbarComponent;
  constructor(
    public routeService: RouteService,
    public router: Router,
    public route: ActivatedRoute,
    public pageLinkService: PageLinksService,
    public pdfService: PdfService,
    public der: ChangeDetectorRef,
    public itemService: ItemDataService
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.id !== undefined && params.id !== null) {
        this.itemId = params.id;
        this.pageLinkService.initPageLinks(params?.id);
        this.loadData(this.itemId);
        this.der.detectChanges();
      }
    });
  }
  loadData(itemId: string) {
    this.pageLinkService.initPageLinks(itemId);

    this.itemService
      .findById(itemId, true, false, ...ITEM_PAGE_LINKS_TO_FOLLOW)
      .subscribe((data) => {
        data?.payload?.thumbnail?.subscribe((img) => {
          const srcImage = img?.payload?._links?.content?.href;
          if (srcImage !== undefined) {
            this.imageSrc = srcImage;
          } else {
            this.imageSrc = undefined;
          }
        });
        this.pdfData$ = data?.payload;
        this.der.detectChanges();
      });

    // Get PDF URL
    this.pdfService.getLink().subscribe((url) => {
      if (url !== undefined) {
        this.fileUrl = url;
        this.der.detectChanges();
      }
    });
  }

  // Go back method
  handleBack(): void {
    if (this.itemId) {
      void this.router.navigate([`/items/${this.itemId}`]);
    }
  }

  handleEvent() {
    if (this.ItemNavbar) {
      this.ItemNavbar.handleShowMenu();
    }
  }
}
