import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { hasValue } from '../shared/empty.util';
import { Item } from '../core/shared/item.model';
import { toDSpaceObjectListRD } from '../core/shared/operators';
import { ItemDataService } from '../core/data/item-data.service';
import { SearchService } from '../core/shared/search/search.service';
import { BROWSE_LINKS_TO_FOLLOW } from '../core/browse/browse.service';
import { DSpaceObjectType } from '../core/shared/dspace-object-type.model';
import { CollectionDataService } from '../core/data/collection-data.service';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { PaginatedSearchOptions } from '../shared/search/models/paginated-search-options.model';

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'ds-related-second-items',
  styleUrls: ['./related-items.component.scss'],
  templateUrl: './related-items.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RelatedSecondItemsComponent implements OnInit {
  @Input() route;
  @Input() itemId;
  @Input() title;
  delay = 10000;
  loadingData = true;
  dataRelatedItems = [];
  imagesLoading: { [key: string]: boolean } = {};

  breakpoints: Record<number, { slidesPerView: number }> = {
    640: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
    1100: {
      slidesPerView: 3,
    },
  };

  constructor(
    public router: Router,
    public der: ChangeDetectorRef,
    public itemService: ItemDataService,
    public searchService: SearchService,
    public collectionService: CollectionDataService
  ) {}

  ngOnInit(): void {
    if (this.itemId) {
      this.loadOwningCollection(this.itemId);
    } else {
      this.loadingData = false;
    }
  }
  // Load the owning collection by item ID
  private loadOwningCollection(id: string) {
    this.itemService.findById(id).subscribe((item) => {
      if (item) {
        this.getOwningCollectionByItem(item);
      }
    });
  }
  // Get the owning collection for the given item
  private getOwningCollectionByItem(item) {
    if (hasValue(item?.payload?._links)) {
      this.collectionService
        .findOwningCollectionFor(item?.payload)
        .subscribe((collection) => {
          const idCollection = collection?.payload?.id;
          this.getItemsOfCollection(idCollection);
        });
    }
  }
  // Get items of the given collection ID
  private getItemsOfCollection(id): any {
    let items;
    this.searchService
      .search<Item>(
        new PaginatedSearchOptions({
          scope: id,
          dsoTypes: [DSpaceObjectType.ITEM],
        }),
        null,
        true,
        true,
        ...BROWSE_LINKS_TO_FOLLOW
      )
      .pipe(toDSpaceObjectListRD())
      .subscribe((item) => {
        this.loadingData = item?.isLoading;
        if (item?.payload?.page.length >= 10) {
          items = item?.payload?.page?.slice(0, 12);
        } else {
          items = item?.payload?.page;
        }
      });
    this.loadRelatedItems(items);
    this.der.detectChanges();
  }
  // Load related items and process their metadata
  private loadRelatedItems(items) {
    if (hasValue(items)) {
      this.dataRelatedItems = items
        .filter((item) => item?.id !== this.itemId)
        .map((data) => ({
          id: data.id || '',
          redirect:
            this.route !== undefined ? `/${this.route}/${data?.id}` : '',
          date: data.metadata['dc.date.issued']
            ? data.metadata['dc.date.issued'][0]?.value
            : 'No hay fecha disponible',
          title: data.metadata['dc.title']
            ? data.metadata['dc.title'][0]?.value
            : 'No hay título disponible',
          editorial: data.metadata['dc.publisher']
            ? data.metadata['dc.publisher'][0]?.value
            : 'No hay título disponible',
          image: this.getImageItems(data),
        }));
      this.der.detectChanges();
    }
  }
  // Get image details for the item
  private getImageItems(data) {
    if (hasValue(data)) {
      let imageDetails = { id: '', image: null, isLoading: true };
      data.thumbnail?.subscribe((image: any) => {
        const img = image?.payload?._links?.content?.href || null;
        const isLoading = image?.payload?.isLoading || false;
        imageDetails = {
          id: image?.payload?.id || '',
          image: img,
          isLoading: isLoading,
        };
      });
      this.imagesLoading[data.id] = true;
      return imageDetails;
    }
    return { id: '', image: null, isLoading: false };
  }

  // Go item handle
  handleRedirect(route: string) {
    if (hasValue(route)) {
      this.router.navigate([route]);
      this.der.detectChanges();
    }
  }
  // Success handler for when an image has loaded
  successHandler(id: string): void {
    this.imagesLoading[id] = false;
  }
  // Error handler for when an image fails to load
  errorHandler(id: string): void {
    this.imagesLoading[id] = false;
  }
}
